export interface Option {
  value: string;
  label: string;
  children?: Option[];
}

export const Industry: Option[] = [
  {
    value: '208000',
    label: '文化、体育和娱乐业‥',
    children: [
      {
        value: '208100',
        label: '文化、体育和娱乐业‥',
        children: [
          {
            value: '208101',
            label: '新闻和出版业',
          },
          {
            value: '208102',
            label: '广播、电视、电影和录音制作业',
          },
          {
            value: '208103',
            label: '文化艺术业',
          },
          {
            value: '208104',
            label: '体育',
          },
          {
            value: '208105',
            label: '娱乐业',
          },
        ],
      },
    ],
  },
  {
    value: '202000',
    label: '租赁和商务服务业',
    children: [
      {
        value: '202100',
        label: '租赁和商务服务业',
        children: [
          {
            value: '202101',
            label: '租赁业',
          },
          {
            value: '202102',
            label: '商务服务业',
          },
        ],
      },
    ],
  },
  {
    value: '150000',
    label: '建筑业',
    children: [
      {
        value: '150100',
        label: '建筑业',
        children: [
          {
            value: '150101',
            label: '房屋建筑业',
          },
          {
            value: '150102',
            label: '土木工程建筑业',
          },
          {
            value: '150103',
            label: '建筑安装业',
          },
          {
            value: '150104',
            label: '建筑装饰、装修和其他建筑业',
          },
        ],
      },
    ],
  },
  {
    value: '190000',
    label: '信息传输、软件和信息技术服务业',
    children: [
      {
        value: '190100',
        label: '信息传输、软件和信息技术服务业',
        children: [
          {
            value: '190101',
            label: '电信、广播电视和卫星传输服务',
          },
          {
            value: '190102',
            label: '互联网和相关服务',
          },
          {
            value: '190103',
            label: '软件和信息技术服务业',
          },
        ],
      },
    ],
  },
  {
    value: '180000',
    label: '住宿和餐饮业',
    children: [
      {
        value: '180100',
        label: '住宿和餐饮业',
        children: [
          {
            value: '180101',
            label: '住宿业',
          },
          {
            value: '180102',
            label: '餐饮业',
          },
        ],
      },
    ],
  },
  {
    value: '160000',
    label: '批发和零售业',
    children: [
      {
        value: '160100',
        label: '批发和零售业',
        children: [
          {
            value: '160101',
            label: '批发业',
          },
          {
            value: '160102',
            label: '零售业',
          },
        ],
      },
    ],
  },
  {
    value: '110000',
    label: '农林牧渔',
    children: [
      {
        value: '110100',
        label: '农林牧渔',
        children: [
          {
            value: '110101',
            label: '农业',
          },
          {
            value: '110102',
            label: '林业',
          },
          {
            value: '110103',
            label: '畜牧业',
          },
          {
            value: '110104',
            label: '渔业',
          },
          {
            value: '110105',
            label: '农、林、牧、渔专业及辅助性活动',
          },
        ],
      },
      {
        value: '110200',
        label: '其他农业',
        children: [
          {
            value: '110201',
            label: '其他农业',
          },
        ],
      },
    ],
  },
  {
    value: '120000',
    label: '采矿业',
    children: [
      {
        value: '120100',
        label: '采矿业',
        children: [
          {
            value: '120101',
            label: '煤炭开采和洗选业',
          },
          {
            value: '120102',
            label: '石油和天然气开采业',
          },
          {
            value: '120103',
            label: '黑色金属矿采选业',
          },
          {
            value: '120104',
            label: '有色金属矿采选业',
          },
          {
            value: '120105',
            label: '非金属矿采选业',
          },
          {
            value: '120106',
            label: '开采专业及辅助性活动',
          },
          {
            value: '120107',
            label: '其他采矿业',
          },
        ],
      },
    ],
  },
  {
    value: '130000',
    label: '制造业',
    children: [
      {
        value: '130100',
        label: '制造业',
        children: [
          {
            value: '130101',
            label: '农副食品加工业',
          },
          {
            value: '130102',
            label: '食品制造业',
          },
          {
            value: '130103',
            label: '酒、饮料和精制茶制造业',
          },
          {
            value: '130104',
            label: '烟草制品业',
          },
          {
            value: '130105',
            label: '纺织业',
          },
          {
            value: '130106',
            label: '纺织服装、服饰业',
          },
          {
            value: '130107',
            label: '皮革、毛皮、羽毛及其制品和制鞋业',
          },
          {
            value: '130108',
            label: '木材加工和木、竹、藤、棕、草制品业',
          },
          {
            value: '130109',
            label: '家具制造业',
          },
          {
            value: '130110',
            label: '造纸和纸制品业',
          },
          {
            value: '130111',
            label: '印刷和记录媒介复制业',
          },
          {
            value: '130112',
            label: '文教、工美、体育和娱乐用品制造业',
          },
          {
            value: '130113',
            label: '石油、煤炭及其他燃料加工业',
          },
          {
            value: '130114',
            label: '化学原料和化学制品制造业',
          },
          {
            value: '130115',
            label: '医药制造业',
          },
          {
            value: '130116',
            label: '化学纤维制造业',
          },
          {
            value: '130117',
            label: '橡胶和塑料制品业',
          },
          {
            value: '130118',
            label: '非金属矿物制品业',
          },
          {
            value: '130119',
            label: '黑色金属冶炼和压延加工业',
          },
          {
            value: '130120',
            label: '有色金属冶炼和压延加工业',
          },
          {
            value: '130121',
            label: '金属制品业',
          },
          {
            value: '130122',
            label: '通用设备制造业',
          },
          {
            value: '130123',
            label: '专用设备制造业 ',
          },
          {
            value: '130124',
            label: '汽车制造业',
          },
          {
            value: '130125',
            label: '铁路、船舶、航空航天和其他运输设备制造业',
          },
          {
            value: '130126',
            label: '电气机械和器材制造业 ',
          },
          {
            value: '130127',
            label: '计算机、通信和其他电子设备制造业',
          },
          {
            value: '130128',
            label: '仪器仪表制造业',
          },
          {
            value: '130129',
            label: '其他制造业',
          },
          {
            value: '130130',
            label: '废弃资源综合利用业',
          },
          {
            value: '130131',
            label: '金属制品、机械和设备修理业',
          },
        ],
      },
    ],
  },
  {
    value: '140000',
    label: '电力、热力、燃气及水生产和供应业',
    children: [
      {
        value: '140100',
        label: '电力、热力、燃气及水生产和供应业',
        children: [
          {
            value: '140101',
            label: '电力、热力生产和供应业',
          },
          {
            value: '140102',
            label: '燃气生产和供应业',
          },
          {
            value: '140103',
            label: '水的生产和供应业',
          },
        ],
      },
    ],
  },

  {
    value: '170000',
    label: '交通运输、仓储和邮政业',
    children: [
      {
        value: '170100',
        label: '交通运输、仓储和邮政业',
        children: [
          {
            value: '170101',
            label: '铁路运输业',
          },
          {
            value: '170102',
            label: '道路运输业',
          },
          {
            value: '170103',
            label: '水上运输业',
          },
          {
            value: '170104',
            label: '航空运输业',
          },
          {
            value: '170105',
            label: '管道运输业',
          },
          {
            value: '170106',
            label: '多式联运和运输代理业',
          },
          {
            value: '170107',
            label: '装卸搬运和仓储业',
          },
          {
            value: '170108',
            label: '邮政业',
          },
        ],
      },
    ],
  },

  {
    value: '200000',
    label: '金融业',
    children: [
      {
        value: '200100',
        label: '金融业',
        children: [
          {
            value: '200101',
            label: '货币金融服务',
          },
          {
            value: '200102',
            label: '资本市场服务',
          },
          {
            value: '200103',
            label: '保险业',
          },
          {
            value: '200104',
            label: '其他金融业',
          },
        ],
      },
    ],
  },
  {
    value: '201000',
    label: '房地产业',
    children: [
      {
        value: '201100',
        label: '房地产业',
        children: [
          {
            value: '201101',
            label: '房地产业',
          },
        ],
      },
    ],
  },

  {
    value: '203000',
    label: '科学研究和技术服务业',
    children: [
      {
        value: '203100',
        label: '科学研究和技术服务业',
        children: [
          {
            value: '203101',
            label: '研究和试验发展',
          },
          {
            value: '203102',
            label: '专业技术服务业',
          },
          {
            value: '203103',
            label: '科技推广和应用服务业',
          },
        ],
      },
    ],
  },
  {
    value: '204000',
    label: '水利、环境和公共设施管理业',
    children: [
      {
        value: '204100',
        label: '水利、环境和公共设施管理业',
        children: [
          {
            value: '204101',
            label: '水利管理业',
          },
          {
            value: '204102',
            label: '生态保护和环境治理业',
          },
          {
            value: '204103',
            label: '公共设施管理业',
          },
          {
            value: '204104',
            label: '土地管理业',
          },
        ],
      },
    ],
  },
  {
    value: '205000',
    label: '居民服务、修理和其他服务业',
    children: [
      {
        value: '205100',
        label: '居民服务、修理和其他服务业',
        children: [
          {
            value: '205101',
            label: '居民服务业',
          },
          {
            value: '205101',
            label: '机动车、电子产品和日用产品修理业',
          },
          {
            value: '205101',
            label: '其他服务业',
          },
        ],
      },
    ],
  },
  {
    value: '206000',
    label: '教育',
    children: [
      {
        value: '206100',
        label: '教育',
        children: [
          {
            value: '206101',
            label: '教育',
          },
        ],
      },
    ],
  },
  {
    value: '207000',
    label: '卫生和社会工作',
    children: [
      {
        value: '207100',
        label: '卫生和社会工作',
        children: [
          {
            value: '207101',
            label: '卫生',
          },
          {
            value: '207102',
            label: '社会工作',
          },
        ],
      },
    ],
  },

  {
    value: '209000',
    label: '公共管理、社会保障和社会组织',
    children: [
      {
        value: '209100',
        label: '公共管理、社会保障和社会组织',
        children: [
          {
            value: '209101',
            label: '中国共产党机关',
          },
          {
            value: '209102',
            label: '国家机构',
          },
          {
            value: '209103',
            label: '人民政协、民主党派',
          },
          {
            value: '209104',
            label: '社会保障',
          },
          {
            value: '209105',
            label: '群众团体、社会团体和其他成员组织',
          },
          {
            value: '209106',
            label: '基层群众自治组织及其他组织',
          },
        ],
      },
    ],
  },
  {
    value: '300000',
    label: '国际组织',
    children: [
      {
        value: '300100',
        label: '国际组织',
        children: [
          {
            value: '300101',
            label: '国际组织',
          },
        ],
      },
    ],
  },
];

//   行业大类	行业子类
// 管理咨询	管理咨询
// 制造业	培训导师
// 银行及金融	代理记账
// 生物科技	新零售电商
// 汽车及自动化	进出口贸易
// 媒体传播	媒体传播
// 商业固定资产	新能源
// 通信及网络	海事海工
// 人力资源	培训导师

export const industryfillter = [
  {
    label: '管理咨询',
    value: 'management_consulting',
    children: [
      {
        label: '北京',
        value: 'beijing1',
      },
      {
        label: '上海',
        value: 'shanghai2',
      },
    ],
  },
  {
    label: '制造业',
    value: 'manufacturing_industry',
    children: [
      {
        label: '北京3',
        value: 'beijing3',
      },
      {
        label: '上海4',
        value: 'shanghai4',
      },
    ],
  },
  // {
  //   label: '银行及金融',
  //   value: 'city',
  //   children: [
  //     {
  //       label: '北京',
  //       value: 'beijing',
  //     },
  //     {
  //       label: '上海',
  //       value: 'shanghai',
  //     },
  //   ],
  // },
  // {
  //   label: '生物科技',
  //   value: 'city',
  //   children: [
  //     {
  //       label: '北京',
  //       value: 'beijing',
  //     },
  //     {
  //       label: '上海',
  //       value: 'shanghai',
  //     },
  //   ],
  // },
  // {
  //   label: '汽车及自动化',
  //   value: 'city',
  //   children: [
  //     {
  //       label: '北京',
  //       value: 'beijing',
  //     },
  //     {
  //       label: '上海',
  //       value: 'shanghai',
  //     },
  //   ],
  // },
  // {
  //   label: '媒体传播',
  //   value: 'city',
  //   children: [
  //     {
  //       label: '北京',
  //       value: 'beijing',
  //     },
  //     {
  //       label: '上海',
  //       value: 'shanghai',
  //     },
  //   ],
  // },
  // {
  //   label: '商业固定资产',
  //   value: 'city',
  //   children: [
  //     {
  //       label: '北京',
  //       value: 'beijing',
  //     },
  //     {
  //       label: '上海',
  //       value: 'shanghai',
  //     },
  //   ],
  // },
  // {
  //   label: '商业固定资产',
  //   value: 'city',
  //   children: [
  //     {
  //       label: '北京',
  //       value: 'beijing',
  //     },
  //     {
  //       label: '上海',
  //       value: 'shanghai',
  //     },
  //   ],
  // },
  // {
  //   label: '通信及网络',
  //   value: 'city',
  //   children: [
  //     {
  //       label: '北京',
  //       value: 'beijing',
  //     },
  //     {
  //       label: '上海',
  //       value: 'shanghai',
  //     },
  //   ],
  // },
  // {
  //   label: '人力资源',
  //   value: 'city',
  //   children: [
  //     {
  //       label: '北京',
  //       value: 'beijing',
  //     },
  //     {
  //       label: '上海',
  //       value: 'shanghai',
  //     },
  //   ],
  // },
];
export default Industry;
