// value值前添加父级字段financial

export const specialData = [
  {
    label: '高级管理',
    value: 'Senior_management',
    children: [
      {
        label: '高级管理职位',
        value: 'senior_management_positions',
        children: [
          { label: '总裁/总经理CEO', value: 'president_ceo' },
          { label: '副总裁/副总经理AVP', value: 'vice_president_avp' },

          {
            label: '总助/CEO助理/董事长助理',
            value: 'chief_assistant_ceo_chairman_assistant',
          },
          {
            label: '区域负责人（辖多个分公司）',
            value: 'regional_head_multiple_branches',
          },
          {
            label: '分公司代表处负责人',
            value: 'branch_office_head',
          },
          {
            label: '董事会秘书',
            value: 'board_secretary',
          },
          {
            label: '联合创始人',
            value: 'co_founder',
          },
          {
            label: '高级管理职位',
            value: 'senior_management_position',
          },
        ],
      },
    ],
  },
  {
    label: '市场/公关/广告',
    value: 'market/PR/advertising',
    children: [
      {
        label: '市场营销',
        value: 'marketing',
        children: [
          {
            label: '市场营销策划',
            value: 'marketing_planning',
          },
          {
            groupName: '市场营销',
            groupEnName: 'marketing',
            label: '海外市场',
            value: 'overseas_market',
          },
          {
            groupName: '市场营销',
            groupEnName: 'marketing',
            label: '活动策划执行',
            value: 'event_planning_execution',
          },
          {
            groupName: '市场营销',
            groupEnName: 'marketing',
            label: '会务/会展执行',
            value: 'meeting_exhibition_execution',
          },
          {
            groupName: '市场营销',
            groupEnName: 'marketing',
            label: '会务/会展策划',
            value: 'meeting_exhibition_planning',
          },
          {
            groupName: '市场营销',
            groupEnName: 'marketing',
            label: '商务渠道',
            value: 'business_channels',
          },
          {
            groupName: '市场营销',
            groupEnName: 'marketing',
            label: '市场总监',
            value: 'marketing_director',
          },
          {
            groupName: '市场营销',
            groupEnName: 'marketing',
            label: 'CMO',
            value: 'chief_marketing_officer',
          },
        ],
      },

      {
        label: '推广/投放',
        value: 'promotion_and_advertising',
        children: [
          {
            label: '市场推广/地推',
            value: 'field_marketing_promotion',
          },
          {
            groupName: '推广/投放',
            groupEnName: 'promotion_and_advertising',
            label: '网络推广',
            value: 'online_marketing_promotion',
          },
          {
            groupName: '推广/投放',
            groupEnName: 'promotion_and_advertising',
            label: '游戏推广',
            value: 'game_promotion',
          },
          {
            groupName: '推广/投放',
            groupEnName: 'promotion_and_advertising',
            label: '媒介投放',
            value: 'media_placement',
          },
          {
            groupName: '推广/投放',
            groupEnName: 'promotion_and_advertising',
            label: '媒介商务BD',
            value: 'media_business_development',
          },
          {
            groupName: '推广/投放',
            groupEnName: 'promotion_and_advertising',
            label: '媒介专员',
            value: 'media_specialist',
          },
          {
            groupName: '推广/投放',
            groupEnName: 'promotion_and_advertising',
            label: '媒介经理/总监',
            value: 'media_manager_director',
          },
          {
            groupName: '推广/投放',
            groupEnName: 'promotion_and_advertising',
            label: '信息流优化师',
            value: 'feed_optimization_specialist',
          },
          {
            groupName: '推广/投放',
            groupEnName: 'promotion_and_advertising',
            label: 'SEO',
            value: 'search_engine_optimization',
          },
          {
            groupName: '推广/投放',
            groupEnName: 'promotion_and_advertising',
            label: 'SEM',
            value: 'search_engine_marketing',
          },
        ],
      },

      {
        label: '政府事务',
        value: 'government_affairs',
        children: [
          {
            label: '政府关系',
            value: 'government_relations',
          },
          {
            groupName: '政府事务',
            groupEnName: 'government_affairs',
            label: '项目申报专员',
            value: 'project_declaration_specialist',
          },
          {
            groupName: '政府事务',
            groupEnName: 'government_affairs',
            label: '政策研究',
            value: 'policy_research',
          },
          {
            groupName: '政府事务',
            groupEnName: 'government_affairs',
            label: '社工',
            value: 'social_worker',
          },
        ],
      },

      {
        label: '公关',
        value: 'public_relations',
        children: [
          {
            label: '品牌公关',
            value: 'brand_public_relations',
          },
          {
            groupName: '公关',
            groupEnName: 'public_relations',
            label: '公关总监',
            value: 'public_relations_director',
          },
        ],
      },

      {
        label: '广告',
        value: 'advertising',
        children: [
          {
            label: '策划经理',
            value: 'planning_manager',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告文案',
            value: 'copywriter',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告客户执行',
            value: 'account_executive_advertising',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告/会展项目经理',
            value: 'advertising_event_project_manager',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告创意策划',
            value: 'advertising_creative_planner',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '创意总监',
            value: 'creative_director',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告设计',
            value: 'advertising_designer',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '美术指导',
            value: 'art_director',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告制作',
            value: 'advertising_production',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告审核',
            value: 'advertising_approval',
          },
        ],
      },

      {
        label: '调研分析',
        value: 'research_analysis',
        children: [
          {
            label: '市场调研',
            value: 'market_research',
          },
          {
            groupName: '调研分析',
            groupEnName: 'research_analysis',
            label: '选址开发',
            value: 'site_selection_development',
          },
          {
            groupName: '调研分析',
            groupEnName: 'research_analysis',
            label: '商业数据分析',
            value: 'business_data_analysis',
          },
          {
            groupName: '调研分析',
            groupEnName: 'research_analysis',
            label: '用户研究员',
            value: 'user_researcher',
          },
          {
            groupName: '调研分析',
            groupEnName: 'research_analysis',
            label: '用户研究经理',
            value: 'user_research_manager',
          },
          {
            groupName: '调研分析',
            groupEnName: 'research_analysis',
            label: '用户研究总监',
            value: 'user_research_director',
          },
        ],
      },

      {
        label: '广告/会展销售',
        value: 'advertising_event_sales',
        children: [
          {
            label: '广告销售',
            value: 'advertising_sales',
          },
          {
            groupName: '广告/会展销售',
            groupEnName: 'advertising_event_sales',
            label: '会展活动销售',
            value: 'event_sales',
          },
          {
            groupName: '广告/会展销售',
            groupEnName: 'advertising_event_sales',
            label: '会议活动销售',
            value: 'conference_sales',
          },
        ],
      },
    ],
  },
  {
    label: '咨询/翻译/法律',
    value: 'Consultation/Translation/Legal',
    children: [
      {
        label: '咨询/调研',
        value: 'consulting_research',
        children: [
          {
            label: '知识产权/专利/商标代理人',
            value: 'ip_patent_trademark_agent',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '项目申报专员',
            value: 'project_declaration_specialist',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '咨询项目管理',
            value: 'consulting_project_manager',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '企业管理咨询',
            value: 'enterprise_management_consultant',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '战略咨询',
            value: 'strategy_consultant',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '财务顾问',
            value: 'financial_consultant',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: 'IT咨询顾问',
            value: 'it_consultant',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '咨询经理',
            value: 'consulting_manager',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '人力资源咨询顾问',
            value: 'hr_consultant',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '猎头顾问',
            value: 'headhunter_consultant',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '咨询总监',
            value: 'consulting_director',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '市场调研',
            value: 'market_researcher',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '数据分析师',
            value: 'data_analyst',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '心理咨询师',
            value: 'psychological_consultant',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '婚恋咨询师',
            value: 'marriage_consultant',
          },
          {
            groupName: '咨询/调研',
            groupEnName: 'consulting_research',
            label: '其他咨询顾问',
            value: 'other_consulting_specialist',
          },
        ],
      },

      {
        label: '翻译',
        value: 'translation',
        children: [
          {
            label: '英语翻译',
            value: 'english_translator',
          },
          {
            groupName: '翻译',
            groupEnName: 'translation',
            label: '日语翻译',
            value: 'japanese_translator',
          },
          {
            groupName: '翻译',
            groupEnName: 'translation',
            label: '韩语/朝鲜语翻译',
            value: 'korean_translator',
          },
          {
            groupName: '翻译',
            groupEnName: 'translation',
            label: '俄语翻译',
            value: 'russian_translator',
          },
          {
            groupName: '翻译',
            groupEnName: 'translation',
            label: '西班牙语翻译',
            value: 'spanish_translator',
          },
          {
            groupName: '翻译',
            groupEnName: 'translation',
            label: '德语翻译',
            value: 'german_translator',
          },
          {
            groupName: '翻译',
            groupEnName: 'translation',
            label: '法语翻译',
            value: 'french_translator',
          },
          {
            groupName: '翻译',
            groupEnName: 'translation',
            label: '其他语种翻译',
            value: 'other_language_translator',
          },
        ],
      },

      {
        label: '法律服务',
        value: 'legal_services',
        children: [
          {
            label: '事务所律师',
            value: 'lawyer',
          },
          {
            groupName: '法律服务',
            groupEnName: 'legal_services',
            label: '律师助理',
            value: 'legal_assistant',
          },
          {
            groupName: '法律服务',
            groupEnName: 'legal_services',
            label: '法务',
            value: 'legal_affairs',
          },
          {
            groupName: '法律服务',
            groupEnName: 'legal_services',
            label: '专利律师',
            value: 'patent_lawyer',
          },
          {
            groupName: '法律服务',
            groupEnName: 'legal_services',
            label: '知识产权律师',
            value: 'ip_lawyer',
          },
          {
            groupName: '法律服务',
            groupEnName: 'legal_services',
            label: '知识产权/专利/商标代理人',
            value: 'ip_patent_trademark_agent_legal_service',
          },
        ],
      },
    ],
  },
  {
    label: '销售',
    value: 'sale',
    children: [
      {
        label: '销售',
        value: 'sale',
        children: [
          { label: '销售专员', value: 'sales_specialist' },
          {
            groupName: '销售',
            groupEnName: 'sale',
            label: '电话销售',
            value: 'telesales',
          },
          {
            groupName: '销售',
            groupEnName: 'sale',
            label: '网络销售',
            value: 'online_sales',
          },
          {
            groupName: '销售',
            groupEnName: 'sale',
            label: '渠道销售',
            value: 'channel_sales',
          },
          {
            groupName: '销售',
            groupEnName: 'sale',
            label: '大客户代表',
            value: 'key_account_representative',
          },
          {
            groupName: '销售',
            groupEnName: 'sale',
            label: '客户经理',
            value: 'account_manager',
          },
          {
            groupName: '销售',
            groupEnName: 'sale',
            label: 'BD经理',
            value: 'business_development_manager',
          },
          {
            groupName: '销售',
            groupEnName: 'sale',
            label: '客户代表',
            value: 'client_representative',
          },
          {
            groupName: '销售',
            groupEnName: 'sale',
            label: '销售工程师',
            value: 'sales_engineer',
          },
        ],
      },

      {
        label: '销售管理',
        value: 'sales_management',
        children: [
          { label: '销售经理', value: 'sales_manager' },
          {
            groupName: '销售管理',
            groupEnName: 'sales_management',
            label: '销售总监',
            value: 'sales_director',
          },
          {
            groupName: '销售管理',
            groupEnName: 'sales_management',
            label: '销售VP',
            value: 'sales_vp',
          },
          {
            groupName: '销售管理',
            groupEnName: 'sales_management',
            label: '团队经理',
            value: 'team_manager',
          },
          {
            groupName: '销售管理',
            groupEnName: 'sales_management',
            label: '城市经理',
            value: 'city_manager',
          },
          {
            groupName: '销售管理',
            groupEnName: 'sales_management',
            label: '区域总监',
            value: 'regional_director',
          },
        ],
      },
      {
        label: '销售行政/商务',
        value: 'sales_administration/business',
        children: [
          { label: '销售助理', value: 'sales_assistant' },
          {
            groupName: '销售行政/商务',
            groupEnName: 'sales_administration/business',
            label: '销售运营',
            value: 'sales_operations',
          },
          {
            groupName: '销售行政/商务',
            groupEnName: 'sales_administration/business',
            label: '商务专员',
            value: 'business_specialist',
          },
          {
            groupName: '销售行政/商务',
            groupEnName: 'sales_administration/business',
            label: '商务经理',
            value: 'business_manager',
          },
          {
            groupName: '销售行政/商务',
            groupEnName: 'sales_administration/business',
            label: '商务总监',
            value: 'commercial_director',
          },
          {
            groupName: '销售行政/商务',
            groupEnName: 'sales_administration/business',
            label: '客户成功',
            value: 'customer_success',
          },
        ],
      },

      {
        label: '外贸销售',
        value: 'foreign_trade_sales',
        children: [
          {
            label: '外贸业务员',
            value: 'foreign_trade_salesperson',
          },
          {
            groupName: '外贸销售',
            groupEnName: 'foreign_trade_sales',
            label: '外贸经理',
            value: 'foreign_trade_manager',
          },
        ],
      },

      {
        label: '课程销售',
        value: 'course_sales',
        children: [
          {
            label: '课程顾问',
            value: 'course_consultant',
          },
          {
            groupName: '课程销售',
            groupEnName: 'course_sales',
            label: '招生顾问',
            value: 'enrollment_consultant',
          },
          {
            groupName: '课程销售',
            groupEnName: 'course_sales',
            label: '留学顾问',
            value: 'study_abroad_consultant',
          },
        ],
      },

      {
        label: '汽车销售',
        value: 'car_sales',
        children: [
          { label: '汽车销售', value: 'car_sales' },
          {
            groupName: '汽车销售',
            groupEnName: 'car_sales',
            label: '汽车配件销售',
            value: 'auto_parts_sales',
          },
          {
            groupName: '汽车销售',
            groupEnName: 'car_sales',
            label: '二手车评估师',
            value: 'used_car_evaluator',
          },
        ],
      },

      {
        label: '房地产销售/招商',
        value: 'real_estate_sales_and_investment',
        children: [
          { label: '置业顾问', value: 'property_consultant' },
          {
            groupName: '房地产销售/招商',
            groupEnName: 'real_estate_sales_and_investment',
            label: '地产中介',
            value: 'real_estate_agent',
          },
          {
            groupName: '房地产销售/招商',
            groupEnName: 'real_estate_sales_and_investment',
            label: '物业招商管理',
            value: 'property_investment_management',
          },
          {
            groupName: '房地产销售/招商',
            groupEnName: 'real_estate_sales_and_investment',
            label: '房地产销售总监',
            value: 'real_estate_sales_director',
          },
        ],
      },

      {
        label: '服务业销售',
        value: 'service_industry_sales',
        children: [
          { label: '服务业销售', value: 'service_industry_sales' },
          {
            groupName: '服务业销售',
            groupEnName: 'service_industry_sales',
            label: '服装导购',
            value: 'fashion_sales_assistant',
          },
          {
            groupName: '服务业销售',
            groupEnName: 'service_industry_sales',
            label: '珠宝销售',
            value: 'jewelry_sales',
          },
          {
            groupName: '服务业销售',
            groupEnName: 'service_industry_sales',
            label: '美容顾问',
            value: 'beauty_consultant',
          },
          {
            groupName: '服务业销售',
            groupEnName: 'service_industry_sales',
            label: '化妆品导购',
            value: 'cosmetics_sales_assistant',
          },
          {
            groupName: '服务业销售',
            groupEnName: 'service_industry_sales',
            label: '会籍顾问',
            value: 'membership_consultant',
          },
          {
            groupName: '服务业销售',
            groupEnName: 'service_industry_sales',
            label: '瘦身顾问',
            value: 'weight_loss_consultant',
          },
          {
            groupName: '服务业销售',
            groupEnName: 'service_industry_sales',
            label: '旅游顾问',
            value: 'travel_consultant',
          },
        ],
      },

      {
        label: '医疗销售',
        value: 'medical_sales',
        children: [
          {
            label: '医药代表',
            value: 'pharmaceutical_sales_representative',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '医疗器械销售',
            value: 'medical_device_sales',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '药店店员',
            value: 'pharmacy_assistant',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '药店店长',
            value: 'pharmacy_manager',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '医美咨询',
            value: 'medical_aesthetic_consultant',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '健康顾问',
            value: 'health_consultant',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '口腔咨询师',
            value: 'dental_consultant',
          },
        ],
      },

      {
        label: '广告/会展销售',
        value: 'advertising_exhibition_sales',
        children: [
          {
            label: '广告销售',
            value: 'advertising_sales',
          },
          {
            groupName: '广告/会展销售',
            groupEnName: 'advertising_exhibition_sales',
            label: '会展活动销售',
            value: 'exhibition_event_sales',
          },
          {
            groupName: '广告/会展销售',
            groupEnName: 'advertising_exhibition_sales',
            label: '会议活动销售',
            value: 'conference_event_sales',
          },
        ],
      },

      {
        label: '金融销售',
        value: 'financial_sales',
        children: [
          { label: '理财顾问', value: 'financial_consultant' },
          {
            groupName: '金融销售',
            groupEnName: 'financial_sales',
            label: '保险顾问',
            value: 'insurance_consultant',
          },
          {
            groupName: '金融销售',
            groupEnName: 'financial_sales',
            label: '信用卡销售',
            value: 'credit_card_sales',
          },
          {
            groupName: '金融销售',
            groupEnName: 'financial_sales',
            label: '证券经纪人',
            value: 'securities_broker',
          },
        ],
      },
    ],
  },
  {
    label: '人力/行政/法务',
    value: 'human_resources/administration/legal_affairs',
    children: [
      {
        label: '人力资源',
        value: 'human_resources',
        children: [
          {
            label: '人力资源专员/助理',
            value: 'hr_assistant_specialist',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: '人力资源经理/主管',
            value: 'hr_manager_supervisor',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: '人力资源总监',
            value: 'hr_director',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: '招聘',
            value: 'recruitment',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: 'HRBP',
            value: 'hr_business_partner',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: '培训',
            value: 'training',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: '员工关系',
            value: 'employee_relations',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: '组织发展',
            value: 'organization_development',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: '企业文化',
            value: 'corporate_culture',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: '薪酬绩效',
            value: 'compensation_performance',
          },
          {
            groupName: '人力资源',
            groupEnName: 'human_resources',
            label: '猎头顾问',
            value: 'headhunter_consultant',
          },
        ],
      },

      {
        label: '行政',
        value: 'administration',
        children: [
          {
            label: '行政专员/助理',
            value: 'admin_assistant_specialist',
          },
          {
            groupName: '行政',
            groupEnName: 'administration',
            label: '行政经理/主管',
            value: 'admin_manager_supervisor',
          },
          {
            groupName: '行政',
            groupEnName: 'administration',
            label: '行政总监',
            value: 'admin_director',
          },
          {
            groupName: '行政',
            groupEnName: 'administration',
            label: '前台',
            value: 'receptionist',
          },
          {
            groupName: '行政',
            groupEnName: 'administration',
            label: '后勤',
            value: 'logistics',
          },
          {
            groupName: '行政',
            groupEnName: 'administration',
            label: '经理助理',
            value: 'manager_assistant',
          },
          {
            groupName: '行政',
            groupEnName: 'administration',
            label: '文员',
            value: 'clerk',
          },
          {
            groupName: '行政',
            groupEnName: 'administration',
            label: '企业党建',
            value: 'corporate_party_building',
          },
          {
            groupName: '行政',
            groupEnName: 'administration',
            label: '档案管理',
            value: 'archives_management',
          },
          {
            groupName: '行政',
            groupEnName: 'administration',
            label: '商务司机',
            value: 'business_driver',
          },
        ],
      },
      {
        label: '法律服务',
        value: 'legal_services',
        children: [
          { label: '法务专员/助理', value: 'legal_assistant_specialist' },
          {
            groupName: '法律服务',
            groupEnName: 'legal_services',
            label: '法务经理/主管',
            value: 'legal_manager_supervisor',
          },
          {
            groupName: '法律服务',
            groupEnName: 'legal_services',
            label: '法务总监',
            value: 'legal_director',
          },
          {
            groupName: '法律服务',
            groupEnName: 'legal_services',
            label: '法律顾问',
            value: 'legal_consultant',
          },
          {
            groupName: '法律服务',
            groupEnName: 'legal_services',
            label: '律师',
            value: 'lawyer',
          },
        ],
      },
    ],
  },
  {
    label: '财务/审计/税务',
    value: 'financial',
    children: [
      {
        label: '会计',
        value: 'Accountant',
        children: [
          { label: '总账会计', value: 'chief_accountant' },
          {
            label: '成本会计',
            value: 'cost_accountant',
          },
          {
            label: '结算会计',
            value: 'settlement_accountant',
          },
          {
            label: '税务外勤会计',
            value: 'tax_field_accountant',
          },
          {
            label: '建筑/工程会计',
            value: 'construction/engineering_accountant',
          },
        ],
      },

      {
        label: '审计/税务',
        value: 'auditor/tax_specialist',
        children: [
          { label: '审计', value: 'auditor' },
          {
            label: '税务',
            value: 'tax_officer',
          },
        ],
      },
      {
        label: '高级财务职位',
        value: 'senior_finance_positions',
        children: [
          {
            label: '财务经理/主管',
            value: 'finance_manager/supervisor',
          },
          {
            label: '财务总监/VP',
            value: 'finance_director/VP',
          },
          {
            label: 'CFO',
            value: 'chief_financial_officer',
          },
        ],
      },

      {
        label: '其他财务岗位',
        value: 'other_financial_positions',
        children: [
          {
            label: '出纳',
            value: 'cashier',
          },
          {
            groupName: '其他财务岗位',
            groupEnName: 'other_financial_positions',
            label: '风控',
            value: 'risk_management',
          },
          {
            groupName: '其他财务岗位',
            groupEnName: 'other_financial_positions',
            label: '财务顾问',
            value: 'financial_advisor',
          },
          {
            groupName: '其他财务岗位',
            groupEnName: 'other_financial_positions',
            label: '统计员',
            value: 'statistician',
          },
          {
            groupName: '其他财务岗位',
            groupEnName: 'other_financial_positions',
            label: '财务分析/财务BP',
            value: 'financial_analysis/financial_BP',
          },
        ],
      },
    ],
  },
  {
    label: '设计',
    value: 'design',
    children: [
      {
        label: '视觉/交互设计',
        value: 'visual_interaction_design',
        children: [
          {
            label: '平面设计',
            value: 'graphic_designer',
          },
          {
            groupName: '视觉/交互设计',
            groupEnName: 'visual_interaction_design',
            label: '美工',
            value: 'art_designer',
          },
          {
            groupName: '视觉/交互设计',
            groupEnName: 'visual_interaction_design',
            label: '设计师助理',
            value: 'designer_assistant',
          },
          {
            groupName: '视觉/交互设计',
            groupEnName: 'visual_interaction_design',
            label: 'UI设计师',
            value: 'ui_designer',
          },
          {
            groupName: '视觉/交互设计',
            groupEnName: 'visual_interaction_design',
            label: '视觉设计师',
            value: 'visual_designer',
          },
          {
            groupName: '视觉/交互设计',
            groupEnName: 'visual_interaction_design',
            label: '广告设计',
            value: 'advertising_designer',
          },
          {
            groupName: '视觉/交互设计',
            groupEnName: 'visual_interaction_design',
            label: '交互设计师',
            value: 'interaction_designer',
          },
          {
            groupName: '视觉/交互设计',
            groupEnName: 'visual_interaction_design',
            label: '网页设计师',
            value: 'web_designer',
          },
          {
            groupName: '视觉/交互设计',
            groupEnName: 'visual_interaction_design',
            label: '多媒体设计师',
            value: 'multimedia_designer',
          },
        ],
      },

      {
        label: '环境设计',
        value: 'environmental_design',
        children: [
          {
            label: '室内设计',
            value: 'interior_designer',
          },
          {
            groupName: '环境设计',
            groupEnName: 'environmental_design',
            label: '家居设计',
            value: 'home_furnishing_designer',
          },
          {
            groupName: '环境设计',
            groupEnName: 'environmental_design',
            label: '软装设计师',
            value: 'soft_decoration_designer',
          },
          {
            groupName: '环境设计',
            groupEnName: 'environmental_design',
            label: '展览/展示设计',
            value: 'exhibition_designer',
          },
          {
            groupName: '环境设计',
            groupEnName: 'environmental_design',
            label: '陈列设计',
            value: 'display_designer',
          },
          {
            groupName: '环境设计',
            groupEnName: 'environmental_design',
            label: '照明设计',
            value: 'lighting_designer',
          },
          {
            groupName: '环境设计',
            groupEnName: 'environmental_design',
            label: '园林/景观设计',
            value: 'landscape_designer',
          },
          {
            groupName: '环境设计',
            groupEnName: 'environmental_design',
            label: '舞美设计师',
            value: 'stage_designer',
          },
          {
            groupName: '环境设计',
            groupEnName: 'environmental_design',
            label: 'CAD绘图员',
            value: 'cad_drafter',
          },
        ],
      },

      {
        label: '工业设计',
        value: 'industrial_design',
        children: [
          {
            label: '家具设计',
            value: 'furniture_designer',
          },
          {
            groupName: '工业设计',
            groupEnName: 'industrial_design',
            label: '橱柜设计',
            value: 'cabinet_designer',
          },
          {
            groupName: '工业设计',
            groupEnName: 'industrial_design',
            label: '工业设计',
            value: 'industrial_designer',
          },
          {
            groupName: '工业设计',
            groupEnName: 'industrial_design',
            label: '包装设计',
            value: 'packaging_designer',
          },
          {
            groupName: '工业设计',
            groupEnName: 'industrial_design',
            label: '珠宝设计',
            value: 'jewelry_designer',
          },
          {
            groupName: '工业设计',
            groupEnName: 'industrial_design',
            label: '家具拆单员',
            value: 'furniture_dismantling_clerk',
          },
        ],
      },

      {
        label: '服装设计',
        value: 'fashion_design',
        children: [
          {
            label: '服装/纺织设计',
            value: 'fashion_textile_designer',
          },
          {
            groupName: '服装设计',
            groupEnName: 'fashion_design',
            label: '面料辅料开发',
            value: 'fabric_and_accessories_developer',
          },
        ],
      },

      {
        label: '美术/3D/动画',
        value: 'art_3d_animation',
        children: [
          {
            label: '3D设计师',
            value: '3d_designer',
          },
          {
            groupName: '美术/3D/动画',
            groupEnName: 'art_3d_animation',
            label: '插画师',
            value: 'illustrator',
          },
          {
            groupName: '美术/3D/动画',
            groupEnName: 'art_3d_animation',
            label: '动画设计',
            value: 'animation_designer',
          },
          {
            groupName: '美术/3D/动画',
            groupEnName: 'art_3d_animation',
            label: '原画师',
            value: 'concept_artist',
          },
          {
            groupName: '美术/3D/动画',
            groupEnName: 'art_3d_animation',
            label: '漫画师',
            value: 'comic_artist',
          },
          {
            groupName: '美术/3D/动画',
            groupEnName: 'art_3d_animation',
            label: '修图师',
            value: 'photo_retoucher',
          },
        ],
      },

      {
        label: '游戏设计',
        value: 'game_design',
        children: [
          {
            label: '游戏场景设计师',
            value: 'game_environment_artist',
          },
          {
            groupName: '游戏设计',
            groupEnName: 'game_design',
            label: '游戏角色设计师',
            value: 'game_character_artist',
          },
          {
            groupName: '游戏设计',
            groupEnName: 'game_design',
            label: '游戏界面设计师',
            value: 'game_ui_designer',
          },
          {
            groupName: '游戏设计',
            groupEnName: 'game_design',
            label: '游戏数值策划',
            value: 'game_numerics_planner',
          },
          {
            groupName: '游戏设计',
            groupEnName: 'game_design',
            label: '系统策划',
            value: 'system_designer',
          },
          {
            groupName: '游戏设计',
            groupEnName: 'game_design',
            label: '游戏特效师',
            value: 'game_fx_artist',
          },
          {
            groupName: '游戏设计',
            groupEnName: 'game_design',
            label: '游戏动作设计师',
            value: 'game_animator',
          },
          {
            groupName: '游戏设计',
            groupEnName: 'game_design',
            label: '技术美术',
            value: 'technical_artist',
          },
          {
            groupName: '游戏设计',
            groupEnName: 'game_design',
            label: '游戏主美术',
            value: 'lead_game_artist',
          },
        ],
      },

      {
        label: '用户研究',
        value: 'user_research',
        children: [
          {
            label: '用户研究员',
            value: 'user_researcher',
          },
          {
            groupName: '用户研究',
            groupEnName: 'user_research',
            label: '用户研究经理',
            value: 'user_research_manager',
          },
          {
            groupName: '用户研究',
            groupEnName: 'user_research',
            label: '用户研究总监',
            value: 'user_research_director',
          },
          {
            groupName: '用户研究',
            groupEnName: 'user_research',
            label: 'UX设计师',
            value: 'ux_designer',
          },
        ],
      },

      {
        label: '高端设计职位',
        value: 'high_end_design_positions',
        children: [
          {
            label: '设计经理/主管',
            value: 'design_manager_or_supervisor',
          },
          {
            groupName: '高端设计职位',
            groupEnName: 'high_end_design_positions',
            label: '设计总监',
            value: 'design_director',
          },
          {
            groupName: '高端设计职位',
            groupEnName: 'high_end_design_positions',
            label: '视觉设计总监',
            value: 'visual_design_director',
          },
        ],
      },
    ],
  },
  {
    label: '能源/环保/农业',
    value: 'Energy/Environmental Protection/Agriculture',
    children: [
      {
        label: '能源/地质',
        value: 'energy_geology',
        children: [
          {
            label: '光伏系统工程师',
            value: 'photovoltaic_system_engineer',
          },
          {
            groupName: '能源/地质',
            groupEnName: 'energy_geology',
            label: '风电/光伏运维工程师',
            value: 'wind_solar_operation_and_maintenance_engineer',
          },
          {
            groupName: '能源/地质',
            groupEnName: 'energy_geology',
            label: '水利工程师',
            value: 'hydraulic_engineer',
          },
          {
            groupName: '能源/地质',
            groupEnName: 'energy_geology',
            label: '地质工程师',
            value: 'geological_engineer',
          },
        ],
      },

      {
        label: '环保',
        value: 'environmental_protection',
        children: [
          {
            label: 'EHS工程师',
            value: 'ehs_engineer',
          },
          {
            groupName: '环保',
            groupEnName: 'environmental_protection',
            label: '环境采样/检测员',
            value: 'environmental_sampling_inspector',
          },
          {
            groupName: '环保',
            groupEnName: 'environmental_protection',
            label: '环评工程师',
            value: 'environmental_impact_assessment_engineer',
          },
          {
            groupName: '环保',
            groupEnName: 'environmental_protection',
            label: '环保工程师',
            value: 'environmental_protection_engineer',
          },
          {
            groupName: '环保',
            groupEnName: 'environmental_protection',
            label: '碳排放管理师',
            value: 'carbon_emission_management_specialist',
          },
        ],
      },
      {
        label: '农/林/牧/渔',
        value: 'agriculture_forestry_animal_husbandry_fisheries',
        children: [
          {
            label: '农业/林业技术员',
            value: 'agriculture_forestry_technician',
          },
          {
            groupName: '农/林/牧/渔',
            groupEnName: 'agriculture_forestry_animal_husbandry_fisheries',
            label: '饲养员',
            value: 'animal_breeder',
          },
          {
            groupName: '农/林/牧/渔',
            groupEnName: 'agriculture_forestry_animal_husbandry_fisheries',
            label: '养殖技术员',
            value: 'aquaculture_technician',
          },
          {
            groupName: '农/林/牧/渔',
            groupEnName: 'agriculture_forestry_animal_husbandry_fisheries',
            label: '畜牧兽医',
            value: 'animal_husbandry_and_veterinary_medicine',
          },
        ],
      },
    ],
  },

  {
    label: '金融',
    value: 'finance',
    children: [
      {
        label: '银行',
        value: 'banking',
        children: [
          { label: '银行柜员', value: 'bank_teller' },
          {
            groupName: '银行',
            groupEnName: 'banking',
            label: '银行大堂经理',
            value: 'bank_lobby_manager',
          },
          {
            groupName: '银行',
            groupEnName: 'banking',
            label: '客户经理',
            value: 'account_manager',
          },
          {
            groupName: '银行',
            groupEnName: 'banking',
            label: '信贷专员',
            value: 'credit_specialist',
          },
        ],
      },
      {
        label: '证券/基金/期货',
        value: 'securities_funds_futures',
        children: [
          { label: '证券交易员', value: 'securities_trader' },
          {
            groupName: '证券/基金/期货',
            groupEnName: 'securities_funds_futures',
            label: '卖方分析师',
            value: 'sell_side_analyst',
          },
          {
            groupName: '证券/基金/期货',
            groupEnName: 'securities_funds_futures',
            label: '买方分析师',
            value: 'buy_side_analyst',
          },
          {
            groupName: '证券/基金/期货',
            groupEnName: 'securities_funds_futures',
            label: '投资银行业务',
            value: 'investment_banking',
          },
          {
            groupName: '证券/基金/期货',
            groupEnName: 'securities_funds_futures',
            label: '基金经理',
            value: 'fund_manager',
          },
          {
            groupName: '证券/基金/期货',
            groupEnName: 'securities_funds_futures',
            label: '柜员',
            value: 'teller',
          },
          {
            groupName: '证券/基金/期货',
            groupEnName: 'securities_funds_futures',
            label: '量化研究员',
            value: 'quantitative_researcher',
          },
        ],
      },

      {
        label: '中后台',
        value: 'middle_back_office',
        children: [
          { label: '风控', value: 'risk_control' },
          {
            groupName: '中后台',
            groupEnName: 'middle_back_office',
            label: '合规稽查',
            value: 'compliance_audit',
          },
          {
            groupName: '中后台',
            groupEnName: 'middle_back_office',
            label: '法务',
            value: 'legal',
          },
          {
            groupName: '中后台',
            groupEnName: 'middle_back_office',
            label: '资信评估',
            value: 'credit_assessment',
          },
          {
            groupName: '中后台',
            groupEnName: 'middle_back_office',
            label: '清算',
            value: 'settlement',
          },
          {
            groupName: '中后台',
            groupEnName: 'middle_back_office',
            label: '资产评估',
            value: 'asset_valuation',
          },
          {
            groupName: '中后台',
            groupEnName: 'middle_back_office',
            label: '金融产品经理',
            value: 'financial_product_manager',
          },
          {
            groupName: '中后台',
            groupEnName: 'middle_back_office',
            label: '催收员',
            value: 'debt_collector',
          },
        ],
      },

      {
        label: '投融资',
        value: 'investment_financing',
        children: [
          { label: '投资经理', value: 'investment_manager' },
          {
            groupName: '投融资',
            groupEnName: 'investment_financing',
            label: '投资助理',
            value: 'investment_assistant',
          },
          {
            groupName: '投融资',
            groupEnName: 'investment_financing',
            label: '行业研究',
            value: 'industry_research',
          },
          {
            groupName: '投融资',
            groupEnName: 'investment_financing',
            label: '融资',
            value: 'financing',
          },
          {
            groupName: '投融资',
            groupEnName: 'investment_financing',
            label: '投后管理',
            value: 'post_investment_management',
          },
          {
            groupName: '投融资',
            groupEnName: 'investment_financing',
            label: '并购',
            value: 'mergers_and_acquisitions',
          },
          {
            groupName: '投融资',
            groupEnName: 'investment_financing',
            label: '投资总监VP',
            value: 'investment_director_vp',
          },
          {
            groupName: '投融资',
            groupEnName: 'investment_financing',
            label: '投资者关系证券事务代表',
            value: 'investor_relations_securities_representative',
          },
          {
            groupName: '投融资',
            groupEnName: 'investment_financing',
            label: '其他投融资职位',
            value: 'other_investment_financing_positions',
          },
        ],
      },

      {
        label: '保险',
        value: 'insurance',
        children: [
          { label: '保险理赔', value: 'insurance_claims_adjuster' },
          {
            groupName: '保险',
            groupEnName: 'insurance',
            label: '保险精算师',
            value: 'actuary',
          },
        ],
      },
      {
        label: '金融销售',
        value: 'financial_sales',
        children: [
          { label: '理财顾问', value: 'financial_consultant' },
          {
            groupName: '金融销售',
            groupEnName: 'financial_sales',
            label: '保险顾问',
            value: 'insurance_consultant',
          },
          {
            groupName: '金融销售',
            groupEnName: 'financial_sales',
            label: '信用卡销售',
            value: 'credit_card_sales',
          },
          {
            groupName: '金融销售',
            groupEnName: 'financial_sales',
            label: '证券经纪人',
            value: 'securities_broker',
          },
        ],
      },
    ],
  },
  {
    label: '医疗健康',
    value: 'healthcare',
    children: [
      {
        label: '护士护理',
        value: 'nursing_care',
        children: [
          {
            label: '护士',
            value: 'nurse',
          },
          {
            groupName: '护士护理',
            groupEnName: 'nursing_care',
            label: '导医',
            value: 'medical_guide',
          },
          {
            groupName: '护士护理',
            groupEnName: 'nursing_care',
            label: '护士长',
            value: 'head_nurse',
          },
          {
            groupName: '护士护理',
            groupEnName: 'nursing_care',
            label: '护工',
            value: 'nursing_assistant',
          },
        ],
      },

      {
        label: '医生/医技',
        value: 'medical_professionals',
        children: [
          {
            label: '外科医生',
            value: 'surgeon',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '内科医生',
            value: 'internist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '皮肤科医生',
            value: 'dermatologist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '妇产科医生',
            value: 'obstetrician_gynecologist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '儿科医生',
            value: 'pediatrician',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '眼科医生',
            value: 'ophthalmologist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '精神心理科医生',
            value: 'psychiatrist_psychologist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '整形医生',
            value: 'plastic_surgeon',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '全科医生',
            value: 'general_practitioner',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '耳鼻喉科医生',
            value: 'otolaryngologist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '检验科医师',
            value: 'laboratory_physician',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '放射科医生',
            value: 'radiologist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '超声科医生',
            value: 'sonographer_ultrasound_physician',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '麻醉科医生',
            value: 'anesthesiologist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '病理科医生',
            value: 'pathologist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '医生助理',
            value: 'physician_assistant',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '中医',
            value: 'traditional_chinese_medicine_practitioner',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '口腔科医生',
            value: 'dentist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '幼儿园保健医',
            value: 'kindergarten_health_physician',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '药剂师',
            value: 'pharmacist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '验光师',
            value: 'optometrist',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '医务管理',
            value: 'medical_administration',
          },
          {
            groupName: '医生/医技',
            groupEnName: 'medical_professionals',
            label: '其他医生职位',
            value: 'other_medical_positions',
          },
        ],
      },

      {
        label: '保健理疗',
        value: 'healthcare_and_physical_therapy',
        children: [
          {
            label: '理疗师',
            value: 'physiotherapist',
          },
          {
            groupName: '保健理疗',
            groupEnName: 'healthcare_and_physical_therapy',
            label: '营养师/健康管理师',
            value: 'dietitian_nutritionist/health_manager',
          },
          {
            groupName: '保健理疗',
            groupEnName: 'healthcare_and_physical_therapy',
            label: '针灸推拿',
            value: 'acupuncture_and_tuina',
          },
          {
            groupName: '保健理疗',
            groupEnName: 'healthcare_and_physical_therapy',
            label: '康复治疗师',
            value: 'rehabilitation_therapist',
          },
          {
            groupName: '保健理疗',
            groupEnName: 'healthcare_and_physical_therapy',
            label: '产后康复师',
            value: 'postpartum_recovery_specialist',
          },
        ],
      },

      {
        label: '药店',
        value: 'pharmacy',
        children: [
          {
            label: '药店店员',
            value: 'pharmacy_assistant',
          },
          {
            groupName: '药店',
            groupEnName: 'pharmacy',
            label: '执业药师驻店药师',
            value: 'licensed_pharmacist_in_store',
          },
          {
            groupName: '药店',
            groupEnName: 'pharmacy',
            label: '药店店长',
            value: 'pharmacy_manager',
          },
        ],
      },

      {
        label: '生物医药',
        value: 'biomedical_pharmaceutical',
        children: [
          {
            label: '生物学研究人员',
            value: 'biological_researcher',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '医药研发',
            value: 'pharmaceutical_research_and_development',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '生物信息工程师',
            value: 'bioinformatics_engineer',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '药品生产',
            value: 'pharmaceutical_production',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '药品注册',
            value: 'pharmaceutical_registration',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '医药项目经理',
            value: 'pharmaceutical_project_manager',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '细胞培养员',
            value: 'cell_culturist',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '药理研究',
            value: 'pharmacological_research',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '药物合成',
            value: 'drug_synthesis',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '制剂研发',
            value: 'formulation_research_and_development',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '药物分析',
            value: 'drug_analysis',
          },
          {
            groupName: '生物医药',
            groupEnName: 'biomedical_pharmaceutical',
            label: '医疗产品技术支持',
            value: 'medical_product_technical_support',
          },
        ],
      },

      {
        label: '临床试验',
        value: 'clinical_trials',
        children: [
          { label: '临床协调员', value: 'clinical_coordinator' },
          {
            groupName: '临床试验',
            groupEnName: 'clinical_trials',
            label: '临床监查员',
            value: 'clinical_monitor',
          },
          {
            groupName: '临床试验',
            groupEnName: 'clinical_trials',
            label: '临床项目经理',
            value: 'clinical_project_manager',
          },
          {
            groupName: '临床试验',
            groupEnName: 'clinical_trials',
            label: '临床数据分析',
            value: 'clinical_data_analyst',
          },
          {
            groupName: '临床试验',
            groupEnName: 'clinical_trials',
            label: '临床医学经理/专员',
            value: 'clinical_medical_manager_specialist',
          },
          {
            groupName: '临床试验',
            groupEnName: 'clinical_trials',
            label: '临床医学总监',
            value: 'clinical_medical_director',
          },
        ],
      },

      {
        label: '医疗器械',
        value: 'medical_devices',
        children: [
          {
            label: '医疗器械生产/质量管理',
            value: 'medical_device_production_quality_management',
          },
          {
            groupName: '医疗器械',
            groupEnName: 'medical_devices',
            label: '医疗器械研发',
            value: 'medical_device_research_and_development',
          },
          {
            groupName: '医疗器械',
            groupEnName: 'medical_devices',
            label: '医疗器械注册',
            value: 'medical_device_registration',
          },
          {
            groupName: '医疗器械',
            groupEnName: 'medical_devices',
            label: '试剂研发',
            value: 'reagent_research_and_development',
          },
        ],
      },

      {
        label: '医疗销售',
        value: 'medical_sales',
        children: [
          {
            label: '医药代表',
            value: 'pharmaceutical_sales_representative',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '医疗器械销售',
            value: 'medical_device_sales',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '药店店员',
            value: 'pharmacy_assistant',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '药店店长',
            value: 'pharmacy_manager',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '医美咨询',
            value: 'medical_aesthetics_consultant',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '健康顾问',
            value: 'health_consultant',
          },
          {
            groupName: '医疗销售',
            groupEnName: 'medical_sales',
            label: '口腔咨询师',
            value: 'dental_consultant',
          },
        ],
      },

      {
        label: '其他医疗健康职位',
        value: 'other_healthcare_positions',
        children: [
          {
            label: '医学编辑',
            value: 'medical_editor',
          },
          {
            groupName: '其他医疗健康职位',
            groupEnName: 'other_healthcare_positions',
            label: '其他医疗健康职位',
            value: 'other_healthcare_positions',
          },
        ],
      },
    ],
  },
  {
    label: '汽车',
    value: 'car',
    children: [
      {
        label: '新能源汽车',
        value: 'new_energy_vehicles',
        children: [
          {
            label: '电池工程师',
            value: 'battery_engineer',
          },
          {
            groupName: '新能源汽车',
            groupEnName: 'new_energy_vehicles',
            label: '电机工程师',
            value: 'motor_engineer',
          },
        ],
      },

      {
        label: '汽车研发/制造',
        value: 'automotive_r_d_manufacturing',
        children: [
          {
            label: '车身造型设计',
            value: 'body_styling_designer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '汽车电子工程师',
            value: 'automotive_electronics_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '自动驾驶系统工程师',
            value: 'autonomous_driving_system_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '线束设计',
            value: 'wiring_harness_designer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '内外饰设计工程师',
            value: 'interior_exterior_designer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '动力系统工程师',
            value: 'powertrain_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '底盘工程师',
            value: 'chassis_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '汽车零部件设计',
            value: 'automotive_component_designer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '汽车设计',
            value: 'automotive_designer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '汽车质量工程师',
            value: 'automotive_quality_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '总装工程师',
            value: 'assembly_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_d_manufacturing',
            label: '汽车项目管理',
            value: 'automotive_project_manager',
          },
        ],
      },
    ],
  },
  {
    label: '采购/贸易',
    value: 'procurement/trade',
    children: [
      {
        label: '采购',
        value: 'procurement',
        children: [
          {
            label: '采购',
            value: 'procurement',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '采购专员/助理',
            value: 'procurement_specialist_assistant',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '采购经理/主管',
            value: 'procurement_manager_supervisor',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '采购工程师',
            value: 'procurement_engineer',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '买手',
            value: 'buyer',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '供应商质量工程师',
            value: 'supplier_quality_engineer',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '商品专员/助理',
            value: 'merchandising_specialist_assistant',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '商品经理',
            value: 'merchandising_manager',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '采购总监',
            value: 'chief_procurement_officer',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '招标专员',
            value: 'tendering_specialist',
          },
          {
            groupName: '采购',
            groupEnName: 'procurement',
            label: '投标专员',
            value: 'bidding_specialist',
          },
        ],
      },

      {
        label: '外贸销售',
        value: 'foreign_trade_sales',
        children: [
          {
            label: '外贸业务员',
            value: 'foreign_trade_salesman',
          },
          {
            groupName: '外贸销售',
            groupEnName: 'foreign_trade_sales',
            label: '外贸经理',
            value: 'foreign_trade_manager',
          },
        ],
      },

      {
        label: '进出口贸易',
        value: 'import_export_trade',
        children: [
          {
            label: '贸易跟单',
            value: 'trade_merchandiser',
          },
          {
            groupName: '进出口贸易',
            groupEnName: 'import_export_trade',
            label: '单证员',
            value: 'documentation_specialist',
          },
          {
            groupName: '进出口贸易',
            groupEnName: 'import_export_trade',
            label: '报关/报检员',
            value: 'customs_declaration_inspector',
          },
        ],
      },
    ],
  },
  {
    label: '物流/仓储/司机',
    value: 'logistics/warehousing/drivers',
    children: [
      {
        label: '配送理货',
        value: 'distribution_and_picking',
        children: [
          {
            label: '配/理/捡/发货',
            value: 'distribution_picking_packing_shipping',
          },
          {
            groupName: '配送理货',
            groupEnName: 'distribution_and_picking',
            label: '配送员',
            value: 'delivery_person',
          },
          {
            groupName: '配送理货',
            groupEnName: 'distribution_and_picking',
            label: '快递员',
            value: 'courier',
          },
          {
            groupName: '配送理货',
            groupEnName: 'distribution_and_picking',
            label: '搬运工/装卸工',
            value: 'loader_unloader',
          },
          {
            groupName: '配送理货',
            groupEnName: 'distribution_and_picking',
            label: '配送站长',
            value: 'distribution_station_manager',
          },
          {
            groupName: '配送理货',
            groupEnName: 'distribution_and_picking',
            label: '叉车工',
            value: 'forklift_operator',
          },
        ],
      },

      {
        label: '驾驶员',
        value: 'drivers',
        children: [
          {
            label: '货运司机',
            value: 'freight_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '商务司机',
            value: 'business_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '网约车司机',
            value: 'ride_hailing_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '代驾司机',
            value: 'designated_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '驾校教练',
            value: 'driving_school_instructor',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '客运司机',
            value: 'passenger_transport_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '无人机飞手',
            value: 'drone_pilot',
          },
        ],
      },

      {
        label: '仓储',
        value: 'warehousing',
        children: [
          {
            label: '仓储',
            value: 'warehousing',
          },
          {
            groupName: '仓储',
            groupEnName: 'warehousing',
            label: '仓库管理员',
            value: 'warehouse_manager',
          },
          {
            groupName: '仓储',
            groupEnName: 'warehousing',
            label: '仓库文员',
            value: 'warehouse_clerk',
          },
          {
            groupName: '仓储',
            groupEnName: 'warehousing',
            label: '仓库主管/经理',
            value: 'warehouse_supervisor_manager',
          },
        ],
      },

      {
        label: '供应链',
        value: 'supply_chain',
        children: [
          {
            label: '供应链专员',
            value: 'supply_chain_specialist',
          },
          {
            groupName: '供应链',
            groupEnName: 'supply_chain',
            label: '供应链经理',
            value: 'supply_chain_manager',
          },
          {
            groupName: '供应链',
            groupEnName: 'supply_chain',
            label: '供应链总监',
            value: 'supply_chain_director',
          },
          {
            groupName: '供应链',
            groupEnName: 'supply_chain',
            label: '生产计划/物料管理(PMC)',
            value: 'production_planning_material_control',
          },
        ],
      },
    ],
  },

  {
    label: '直播/影视/传媒',
    value: 'live_streaming/film/media',
    children: [
      {
        label: '直播',
        value: 'live_streaming',
        children: [
          {
            label: '主播',
            value: 'anchor',
          },
          {
            groupName: '直播',
            groupEnName: 'live_streaming',
            label: '带货主播',
            value: 'shopping_anchor',
          },
          {
            groupName: '直播',
            groupEnName: 'live_streaming',
            label: '直播运营',
            value: 'live_streaming_operation',
          },
          {
            groupName: '直播',
            groupEnName: 'live_streaming',
            label: '游戏主播',
            value: 'game_anchor',
          },
          {
            groupName: '直播',
            groupEnName: 'live_streaming',
            label: '中控/场控/助播',
            value: 'control_assistant_anchor',
          },
        ],
      },

      {
        label: '影视',
        value: 'film_and_tv',
        children: [
          {
            label: '化妆造型师',
            value: 'makeup_artist',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '演员/配音员',
            value: 'actor_voice_actor',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '艺人助理',
            value: 'artist_assistant',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '主持人/DJ',
            value: 'host_dj',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '模特',
            value: 'model',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '摄影师/摄像师',
            value: 'photographer_videographer',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '导演/编导',
            value: 'director_producer',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '经纪人/星探',
            value: 'agent_talent_scout',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '编剧',
            value: 'screenwriter',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '制片人',
            value: 'producer',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '影视策划',
            value: 'film_and_tv_planner',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '影视发行',
            value: 'film_and_tv_distribution',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '剪辑师',
            value: 'editor',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '视频编辑',
            value: 'video_editor',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '后期制作',
            value: 'post_production',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '动画设计',
            value: 'animation_designer',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '修图师',
            value: 'retoucher',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '灯光师',
            value: 'lighting_technician',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '录音/音效',
            value: 'audio_recording_sound_effects',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '音频编辑',
            value: 'audio_editor',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '影视特效',
            value: 'visual_effects_artist',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '剧本杀主持人',
            value: 'murder_mystery_host',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '儿童引导师',
            value: "children's_guide",
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '放映员',
            value: 'projectionist',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '剧本杀编剧',
            value: 'murder_mystery_screenwriter',
          },
          {
            groupName: '影视',
            groupEnName: 'film_and_tv',
            label: '舞美设计师',
            value: 'stage_designer',
          },
        ],
      },

      {
        label: '广告',
        value: 'advertising',
        children: [
          {
            label: '策划经理',
            value: 'planning_manager',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告文案',
            value: 'advertising_copywriter',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告客户执行',
            value: 'advertising_account_executive',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告/会展项目经理',
            value: 'advertising_event_project_manager',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告创意策划',
            value: 'advertising_creative_planner',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '创意总监',
            value: 'creative_director',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告设计',
            value: 'advertising_designer',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '美术指导',
            value: 'art_director',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告制作',
            value: 'advertising_production',
          },
          {
            groupName: '广告',
            groupEnName: 'advertising',
            label: '广告审核',
            value: 'advertising_review',
          },
        ],
      },

      {
        label: '采编/写作/出版',
        value: 'editing_writing_publishing',
        children: [
          {
            label: '编辑',
            value: 'editor',
          },
          {
            groupName: '采编/写作/出版',
            groupEnName: 'editing_writing_publishing',
            label: '主编/副主编',
            value: 'chief_editor_deputy_chief_editor',
          },
          {
            groupName: '采编/写作/出版',
            groupEnName: 'editing_writing_publishing',
            label: '记者/采编',
            value: 'reporter_editor',
          },
          {
            groupName: '采编/写作/出版',
            groupEnName: 'editing_writing_publishing',
            label: '作者/撰稿人',
            value: 'author_writer',
          },
          {
            groupName: '采编/写作/出版',
            groupEnName: 'editing_writing_publishing',
            label: '校对录入',
            value: 'proofreading_entry',
          },
          {
            groupName: '采编/写作/出版',
            groupEnName: 'editing_writing_publishing',
            label: '印刷排版',
            value: 'printing_typesetting',
          },
          {
            groupName: '采编/写作/出版',
            groupEnName: 'editing_writing_publishing',
            label: '出版发行',
            value: 'publishing_distribution',
          },
          {
            groupName: '采编/写作/出版',
            groupEnName: 'editing_writing_publishing',
            label: '剧本杀编剧',
            value: 'murder_mystery_screenwriter',
          },
        ],
      },
    ],
  },
  {
    label: '房地产/建筑',
    value: 'real_estate/construction',
    children: [
      {
        label: '工程管理',
        value: 'project_management',
        children: [
          {
            label: '建筑施工项目经理',
            value: 'construction_project_manager',
          },
          {
            groupName: '工程管理',
            groupEnName: 'project_management',
            label: '工程造价',
            value: 'engineering_cost',
          },
          {
            groupName: '工程管理',
            groupEnName: 'project_management',
            label: '工程监理',
            value: 'project_supervision',
          },
          {
            groupName: '工程管理',
            groupEnName: 'project_management',
            label: '工程预算',
            value: 'engineering_budget',
          },
          {
            groupName: '工程管理',
            groupEnName: 'project_management',
            label: '施工员',
            value: 'construction_worker',
          },
          {
            groupName: '工程管理',
            groupEnName: 'project_management',
            label: '资料员',
            value: 'document_controller',
          },
          {
            groupName: '工程管理',
            groupEnName: 'project_management',
            label: '材料员',
            value: 'materials_manager',
          },
          {
            groupName: '工程管理',
            groupEnName: 'project_management',
            label: '测绘测量',
            value: 'surveying_and_mapping',
          },
          {
            groupName: '工程管理',
            groupEnName: 'project_management',
            label: '施工安全员',
            value: 'construction_safety_officer',
          },
        ],
      },
      {
        label: '装饰装修',
        value: 'decoration_and_finishing',
        children: [
          {
            label: '室内设计',
            value: 'interior_designer',
          },
          {
            groupName: '装饰装修',
            groupEnName: 'decoration_and_finishing',
            label: '软装设计师',
            value: 'soft_furnishing_designer',
          },
          {
            groupName: '装饰装修',
            groupEnName: 'decoration_and_finishing',
            label: '装修项目经理',
            value: 'decoration_project_manager',
          },
        ],
      },

      {
        label: '物业管理',
        value: 'property_management',
        children: [
          {
            label: '物业经理',
            value: 'property_manager',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '物业管理员',
            value: 'property_administrator',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '综合维修工',
            value: 'general_maintenance_worker',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '物业工程主管',
            value: 'property_engineering_supervisor',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '物业招商管理',
            value: 'property_leasing_manager',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '锅炉工',
            value: 'boiler_operator',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '弱电工',
            value: 'low_voltage_electrician',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '绿化工',
            value: 'landscaper',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '保安',
            value: 'security_guard',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '保安经理',
            value: 'security_manager',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '消防中控员',
            value: 'fire_control_operator',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '消防维保员',
            value: 'fire_maintenance_technician',
          },
          {
            groupName: '物业管理',
            groupEnName: 'property_management',
            label: '水电工',
            value: 'plumber_and_electrician',
          },
        ],
      },

      {
        label: '建筑/规划设计',
        value: 'architecture_planning_design',
        children: [
          {
            label: '土木/土建/结构工程师',
            value: 'civil_structural_engineer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '园林/景观设计',
            value: 'landscape_designer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '建筑设计师',
            value: 'architectural_designer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '建筑工程师',
            value: 'building_engineer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '城市规划设计',
            value: 'urban_planning_designer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '弱电工程师',
            value: 'low_voltage_engineer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '建筑机电工程师',
            value: 'building_electromechanical_engineer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '给排水工程师',
            value: 'water_supply_and_drainage_engineer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '暖通工程师',
            value: 'heating_ventilation_and_air_conditioning_engineer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '幕墙工程师',
            value: 'curtain_wall_engineer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: 'BIM工程师',
            value: 'BIM_engineer',
          },
          {
            groupName: '建筑/规划设计',
            groupEnName: 'architecture_planning_design',
            label: '消防工程师',
            value: 'fire_engineer',
          },
        ],
      },

      {
        label: '房地产规划开发',
        value: 'real_estate_planning_development',
        children: [
          {
            label: '地产招投标',
            value: 'real_estate_tendering_and_bidding',
          },
          {
            groupName: '房地产规划开发',
            groupEnName: 'real_estate_planning_development',
            label: '地产项目管理',
            value: 'real_estate_project_management',
          },
          {
            groupName: '房地产规划开发',
            groupEnName: 'real_estate_planning_development',
            label: '房地产策划',
            value: 'real_estate_planning',
          },
          {
            groupName: '房地产规划开发',
            groupEnName: 'real_estate_planning_development',
            label: '房产评估师',
            value: 'real_estate_appraiser',
          },
        ],
      },

      {
        label: '高端房地产职位',
        value: 'high_end_real_estate_positions',
        children: [
          {
            label: '地产项目总监',
            value: 'real_estate_project_director',
          },
          {
            groupName: '高端房地产职位',
            groupEnName: 'high_end_real_estate_positions',
            label: '地产策划总监',
            value: 'real_estate_planning_director',
          },
          {
            groupName: '高端房地产职位',
            groupEnName: 'high_end_real_estate_positions',
            label: '地产招投标总监',
            value: 'real_estate_tendering_and_bidding_director',
          },
        ],
      },

      {
        label: '房地产销售',
        value: 'real_estate_sales',
        children: [
          { label: '置业顾问', value: 'property_consultant' },
          {
            groupName: '房地产销售',
            groupEnName: 'real_estate_sales',
            label: '地产中介',
            value: 'real_estate_agent',
          },
          {
            groupName: '房地产销售',
            groupEnName: 'real_estate_sales',
            label: '房地产销售总监',
            value: 'real_estate_sales_director',
          },
        ],
      },

      {
        label: '建筑/装修工人',
        value: 'construction_decoration_workers',
        children: [
          {
            label: '电工',
            value: 'electrician',
          },
          {
            groupName: '建筑/装修工人',
            groupEnName: 'construction_decoration_workers',
            label: '焊工',
            value: 'welder',
          },
          {
            groupName: '建筑/装修工人',
            groupEnName: 'construction_decoration_workers',
            label: '木工',
            value: 'carpenter',
          },
          {
            groupName: '建筑/装修工人',
            groupEnName: 'construction_decoration_workers',
            label: '空调工',
            value: 'HVAC_technician',
          },
          {
            groupName: '建筑/装修工人',
            groupEnName: 'construction_decoration_workers',
            label: '油漆工',
            value: 'painter',
          },
          {
            groupName: '建筑/装修工人',
            groupEnName: 'construction_decoration_workers',
            label: '电梯工',
            value: 'elevator_technician',
          },
          {
            groupName: '建筑/装修工人',
            groupEnName: 'construction_decoration_workers',
            label: '泥瓦工',
            value: 'masonry_worker',
          },
        ],
      },
    ],
  },

  {
    label: '教育培训',
    value: 'education_and_training',
    children: [
      {
        label: '教师',
        value: 'teachers',
        children: [
          {
            label: '教师',
            value: 'teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '英语教师',
            value: 'english_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '助教',
            value: 'teaching_assistant',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '数学教师',
            value: 'math_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '语文教师',
            value: 'chinese_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '高中教师',
            value: 'high_school_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '初中教师',
            value: 'middle_school_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '小学教师',
            value: 'elementary_school_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '家教',
            value: 'tutor',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '物理教师',
            value: 'physics_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '化学教师',
            value: 'chemistry_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '理科教师',
            value: 'science_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '日语教师',
            value: 'japanese_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '生物教师',
            value: 'biology_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '文科教师',
            value: 'liberal_arts_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '地理教师',
            value: 'geography_teacher',
          },
          {
            groupName: '教师',
            groupEnName: 'teachers',
            label: '其他外语教师',
            value: 'other_foreign_language_teacher',
          },
        ],
      },

      {
        label: '幼少儿教师',
        value: 'early_childhood_teachers',
        children: [
          {
            label: '幼教',
            value: 'preschool_teacher',
          },
          {
            groupName: '幼少儿教师',
            groupEnName: 'early_childhood_teachers',
            label: '托管老师',
            value: 'after_school_care_teacher',
          },
          {
            groupName: '幼少儿教师',
            groupEnName: 'early_childhood_teachers',
            label: '早教老师',
            value: 'early_education_teacher',
          },
          {
            groupName: '幼少儿教师',
            groupEnName: 'early_childhood_teachers',
            label: '保育员',
            value: 'childcare_worker',
          },
          {
            groupName: '幼少儿教师',
            groupEnName: 'early_childhood_teachers',
            label: '感统训练教师',
            value: 'sensory_integration_trainer',
          },
        ],
      },

      {
        label: '教育行政',
        value: 'educational_administration',
        children: [
          {
            label: '教务管理',
            value: 'academic_affairs_management',
          },
          {
            groupName: '教育行政',
            groupEnName: 'educational_administration',
            label: '校长副校长',
            value: 'president_vice_president',
          },
          {
            groupName: '教育行政',
            groupEnName: 'educational_administration',
            label: '班主任/辅导员',
            value: 'class_advisor_counselor',
          },
          {
            groupName: '教育行政',
            groupEnName: 'educational_administration',
            label: '教学管理',
            value: 'teaching_management',
          },
          {
            groupName: '教育行政',
            groupEnName: 'educational_administration',
            label: '园长副园长',
            value: 'kindergarten_director_assistant_director',
          },
          {
            groupName: '教育行政',
            groupEnName: 'educational_administration',
            label: '就业老师',
            value: 'career_guidance_teacher',
          },
        ],
      },

      {
        label: '运动健身',
        value: 'sports_fitness',
        children: [
          {
            label: '健身教练',
            value: 'fitness_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '体育教师/体育教练',
            value: 'physical_education_teacher_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '篮球教练',
            value: 'basketball_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '瑜伽老师',
            value: 'yoga_instructor',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '游泳教练',
            value: 'swimming_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '跆拳道教练',
            value: 'taekwondo_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '武术教练',
            value: 'martial_arts_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '轮滑教练',
            value: 'roller_skating_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '乒乓球教练',
            value: 'table_tennis_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '足球教练',
            value: 'football_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '羽毛球教练',
            value: 'badminton_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '拳击教练',
            value: 'boxing_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'sports_fitness',
            label: '台球教练/助教',
            value: 'billiards_coach_assistant',
          },
        ],
      },

      {
        label: '文化艺术',
        value: 'arts_and_culture',
        children: [
          {
            label: '美术教师',
            value: 'art_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '舞蹈老师',
            value: 'dance_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '书法教师',
            value: 'calligraphy_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '音乐教师',
            value: 'music_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '播音主持教师',
            value: 'broadcasting_and_hosting_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '钢琴教师',
            value: 'piano_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '古筝教师',
            value: 'guzheng_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '吉他教师',
            value: 'guitar_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '表演教师',
            value: 'acting_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '架子鼓老师',
            value: 'drum_kit_teacher',
          },
          {
            groupName: '文化艺术',
            groupEnName: 'arts_and_culture',
            label: '围棋老师',
            value: 'go_teacher',
          },
        ],
      },

      {
        label: '科学探索培训',
        value: 'science_exploration_training',
        children: [
          {
            label: '乐高教师',
            value: 'lego_teacher',
          },
          {
            groupName: '科学探索培训',
            groupEnName: 'science_exploration_training',
            label: '机器人教师',
            value: 'robotics_teacher',
          },
          {
            groupName: '科学探索培训',
            groupEnName: 'science_exploration_training',
            label: '少儿编程老师',
            value: 'children_coding_teacher',
          },
        ],
      },

      {
        label: '职业培训',
        value: 'vocational_training',
        children: [
          {
            label: '培训师',
            value: 'trainer',
          },
          {
            groupName: '职业培训',
            groupEnName: 'vocational_training',
            label: '拓展培训',
            value: 'outward_bound_training',
          },
          {
            groupName: '职业培训',
            groupEnName: 'vocational_training',
            label: 'IT培训讲师',
            value: 'it_training_instructor',
          },
          {
            groupName: '职业培训',
            groupEnName: 'vocational_training',
            label: '财会培训讲师',
            value: 'accounting_finance_training_instructor',
          },
        ],
      },

      {
        label: '教育产品研发',
        value: 'educational_product_development',
        children: [
          {
            label: '教育产品培训师',
            value: 'Education_product_trainer',
          },
          {
            groupName: '教育产品研发',
            groupEnName: 'educational_product_development',
            label: '课程设计',
            value: 'curriculum_designer',
          },
          {
            groupName: '教育产品研发',
            groupEnName: 'educational_product_development',
            label: '课程编辑',
            value: 'curriculum_editor',
          },
          {
            groupName: '教育产品研发',
            groupEnName: 'educational_product_development',
            label: '培训策划',
            value: 'training_planner',
          },
          {
            groupName: '教育产品研发',
            groupEnName: 'educational_product_development',
            label: '培训研究',
            value: 'training_researcher',
          },
          {
            groupName: '教育产品研发',
            groupEnName: 'educational_product_development',
            label: '其他教育产品研发职位',
            value: 'other_educational_product_development_positions',
          },
        ],
      },

      {
        label: '课程销售',
        value: 'course_sales',
        children: [
          {
            label: '课程顾问',
            value: 'course_consultant',
          },
          {
            groupName: '课程销售',
            groupEnName: 'course_sales',
            label: '招生顾问',
            value: 'enrollment_consultant',
          },
          {
            groupName: '课程销售',
            groupEnName: 'course_sales',
            label: '留学顾问',
            value: 'study_abroad_consultant',
          },
        ],
      },
    ],
  },
  {
    label: '酒店/旅游',
    value: 'Hotel/Tourism',
    children: [
      {
        label: '酒店',
        value: 'hotel_management',
        children: [
          {
            label: '酒店前台',
            value: 'hotel_front_desk',
          },
          {
            groupName: '酒店',
            groupEnName: 'hotel_management',
            label: '礼仪迎宾/接待',
            value: 'hospitality_receptionist',
          },
          {
            groupName: '酒店',
            groupEnName: 'hotel_management',
            label: '客房服务员',
            value: 'room_attendant',
          },
          {
            groupName: '酒店',
            groupEnName: 'hotel_management',
            label: '酒店经理',
            value: 'hotel_manager',
          },
          {
            groupName: '酒店',
            groupEnName: 'hotel_management',
            label: '酒店前厅经理',
            value: 'front_office_manager',
          },
          {
            groupName: '酒店',
            groupEnName: 'hotel_management',
            label: '客房经理',
            value: 'rooms_division_manager',
          },
          {
            groupName: '酒店',
            groupEnName: 'hotel_management',
            label: '民宿管家',
            value: 'home_stay_host',
          },
        ],
      },

      {
        label: '旅游服务',
        value: 'travel_services',
        children: [
          {
            label: '旅游顾问',
            value: 'travel_consultant',
          },
          {
            groupName: '旅游服务',
            groupEnName: 'travel_services',
            label: '导游',
            value: 'tour_guide',
          },
          {
            groupName: '旅游服务',
            groupEnName: 'travel_services',
            label: '讲解员',
            value: 'interpreter_guide',
          },
          {
            groupName: '旅游服务',
            groupEnName: 'travel_services',
            label: '计调',
            value: 'travel_coordinator',
          },
          {
            groupName: '旅游服务',
            groupEnName: 'travel_services',
            label: '票务员',
            value: 'ticketing_agent',
          },
          {
            groupName: '旅游服务',
            groupEnName: 'travel_services',
            label: '签证专员',
            value: 'visa_specialist',
          },
        ],
      },

      {
        label: '旅游产品开发/策划',
        value: 'travel_product_development_planning',
        children: [
          {
            label: '旅游产品经理',
            value: 'travel_product_manager',
          },
          {
            groupName: '旅游产品开发/策划',
            groupEnName: 'travel_product_development_planning',
            label: '旅游策划师',
            value: 'travel_planner',
          },
        ],
      },
    ],
  },
  {
    label: '餐饮',
    value: 'Restaurant',
    children: [
      {
        label: '前厅',
        value: 'front_desk',
        children: [
          {
            label: '服务员',
            value: 'server',
          },
          {
            groupName: '前厅',
            groupEnName: 'front_desk',
            label: '收银',
            value: 'cashier',
          },
          {
            groupName: '前厅',
            groupEnName: 'front_desk',
            label: '礼仪迎宾/接待',
            value: 'greeter_receptionist',
          },
          {
            groupName: '前厅',
            groupEnName: 'front_desk',
            label: '传菜员',
            value: 'busser',
          },
        ],
      },

      {
        label: '后厨',
        value: 'kitchen',
        children: [
          {
            label: '餐饮学徒',
            value: 'culinary_apprentice',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '厨师',
            value: 'chef',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '中餐厨师',
            value: 'chinese_chef',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '烧烤师傅',
            value: 'bbq_chef',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '西餐厨师',
            value: 'western_chef',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '日料厨师',
            value: 'japanese_chef',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '凉菜厨师',
            value: 'cold_dish_chef',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '面点师',
            value: 'pastry_chef',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '后厨',
            value: 'kitchen_staff',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '配菜打荷',
            value: 'vegetable_preparer',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '洗碗工',
            value: 'dishwasher',
          },
          {
            groupName: '后厨',
            groupEnName: 'kitchen',
            label: '水台/水产员',
            value: 'fish_monger',
          },
        ],
      },

      {
        label: '餐饮管理',
        value: 'food_beverage_management',
        children: [
          {
            label: '餐饮店长',
            value: 'restaurant_manager',
          },
          {
            groupName: '餐饮管理',
            groupEnName: 'food_beverage_management',
            label: '餐饮前厅经理/领班',
            value: 'front_of_house_manager_supervisor',
          },
          {
            groupName: '餐饮管理',
            groupEnName: 'food_beverage_management',
            label: '储备店长',
            value: 'assistant_restaurant_manager',
          },
          {
            groupName: '餐饮管理',
            groupEnName: 'food_beverage_management',
            label: '厨师长',
            value: 'head_chef',
          },
          {
            groupName: '餐饮管理',
            groupEnName: 'food_beverage_management',
            label: '行政总厨',
            value: 'executive_chef',
          },
          {
            groupName: '餐饮管理',
            groupEnName: 'food_beverage_management',
            label: '餐饮督导',
            value: 'food_beverage_supervisor',
          },
        ],
      },

      {
        label: '甜点饮品',
        value: 'dessert_beverage',
        children: [
          {
            label: '咖啡师',
            value: 'barista',
          },
          {
            groupName: '甜点饮品',
            groupEnName: 'dessert_beverage',
            label: '茶艺师',
            value: 'tea_artist',
          },
          {
            groupName: '甜点饮品',
            groupEnName: 'dessert_beverage',
            label: '奶茶店店员',
            value: 'bubble_tea_shop_staff',
          },
          {
            groupName: '甜点饮品',
            groupEnName: 'dessert_beverage',
            label: '调酒师',
            value: 'bartender',
          },
          {
            groupName: '甜点饮品',
            groupEnName: 'dessert_beverage',
            label: '面包/烘焙师',
            value: 'baker',
          },
          {
            groupName: '甜点饮品',
            groupEnName: 'dessert_beverage',
            label: '蛋糕/裱花师',
            value: 'cake_decorator',
          },
        ],
      },

      {
        label: '其他餐饮岗位',
        value: 'other_food_beverage_positions',
        children: [
          {
            label: '送餐员',
            value: 'delivery_person',
          },
        ],
      },
    ],
  },
  {
    label: '零售/生活服务',
    value: 'retail/lifestyle_services',
    children: [
      {
        label: '零售',
        value: 'retail',
        children: [
          {
            label: '店员/营业员',
            value: 'store_clerk',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '收银',
            value: 'cashier',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '导购',
            value: 'shopping_guide',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '服装导购',
            value: 'clothing_guide',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '珠宝销售',
            value: 'jewelry_sales',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '化妆品导购',
            value: 'cosmetics_guide',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '理货员',
            value: 'stock_keeper',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '促销员',
            value: 'sales_promoter',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '陈列员',
            value: 'display_specialist',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '防损员',
            value: 'loss_prevention_officer',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '门店店长',
            value: 'store_manager',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '储备店长',
            value: 'assistant_store_manager',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '督导巡店',
            value: 'store_inspector',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '卖场经理',
            value: 'sales_floor_manager',
          },
          {
            groupName: '零售',
            groupEnName: 'retail',
            label: '商场运营',
            value: 'mall_operations_manager',
          },
        ],
      },

      {
        label: '理疗保健',
        value: 'physical_therapy_health',
        children: [
          {
            label: '理疗师',
            value: 'physiotherapist',
          },
          {
            groupName: '理疗保健',
            groupEnName: 'physical_therapy_health',
            label: '针灸推拿',
            value: 'acupuncture_and_massage',
          },
          {
            groupName: '理疗保健',
            groupEnName: 'physical_therapy_health',
            label: '按摩师',
            value: 'massage_therapist',
          },
          {
            groupName: '理疗保健',
            groupEnName: 'physical_therapy_health',
            label: '足疗师',
            value: 'foot_massage_therapist',
          },
          {
            groupName: '理疗保健',
            groupEnName: 'physical_therapy_health',
            label: '采耳师',
            value: 'ear_cleaning_specialist',
          },
        ],
      },

      {
        label: '安保服务',
        value: 'security_services',
        children: [
          {
            label: '保安',
            value: 'security_guard',
          },
          {
            groupName: '安保服务',
            groupEnName: 'security_services',
            label: '保安经理',
            value: 'security_manager',
          },
          {
            groupName: '安保服务',
            groupEnName: 'security_services',
            label: '消防中控员',
            value: 'fire_control_officer',
          },
          {
            groupName: '安保服务',
            groupEnName: 'security_services',
            label: '押运员',
            value: 'escort_officer',
          },
          {
            groupName: '安保服务',
            groupEnName: 'security_services',
            label: '安检员',
            value: 'security_checker',
          },
          {
            groupName: '安保服务',
            groupEnName: 'security_services',
            label: '消防维保员',
            value: 'fire_safety_maintenance_officer',
          },
        ],
      },

      {
        label: '家政保洁/维修',
        value: 'housekeeping_maintenance',
        children: [
          {
            label: '保洁',
            value: 'cleaning',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '保洁经理',
            value: 'cleaning_manager',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '保姆',
            value: 'nanny',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '月嫂',
            value: 'maternity_nurse',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '产后康复师',
            value: 'postpartum_recovery_specialist',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '育婴师',
            value: 'baby_care_specialist',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '护工',
            value: 'nursing_assistant',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '收纳师',
            value: 'organizer',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '家电维修',
            value: 'home_appliance_repair',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '手机维修',
            value: 'mobile_phone_repair',
          },
          {
            groupName: '家政保洁/维修',
            groupEnName: 'housekeeping_maintenance',
            label: '电脑打印机维修',
            value: 'computer_printer_repair',
          },
        ],
      },

      {
        label: '美容美发',
        value: 'beauty_hairdressing',
        children: [
          {
            label: '美容师',
            value: 'beautician',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '美容店长',
            value: 'beauty_salon_manager',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '美体师',
            value: 'body_beautician',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '美容顾问',
            value: 'beauty_consultant',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '美容导师',
            value: 'beauty_instructor',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '美甲美睫师',
            value: 'nail_and_eyelash_artist',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '纹绣师',
            value: 'permanent_makeup_artist',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '发型师',
            value: 'hairstylist',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '美发助理/学徒',
            value: 'hairdressing_assistant_apprentice',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '养发师',
            value: 'hair_care_specialist',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '瘦身顾问',
            value: 'weight_loss_consultant',
          },
          {
            groupName: '美容美发',
            groupEnName: 'beauty_hairdressing',
            label: '化妆造型师',
            value: 'makeup_artist_stylist',
          },
        ],
      },

      {
        label: '汽车服务',
        value: 'automotive_services',
        children: [
          {
            label: '汽车维修',
            value: 'auto_repair',
          },
          {
            groupName: '汽车服务',
            groupEnName: 'automotive_services',
            label: '汽车美容',
            value: 'auto_detailing',
          },
          {
            groupName: '汽车服务',
            groupEnName: 'automotive_services',
            label: '洗车工',
            value: 'car_washer',
          },
          {
            groupName: '汽车服务',
            groupEnName: 'automotive_services',
            label: '汽车改装',
            value: 'auto_modification',
          },
          {
            groupName: '汽车服务',
            groupEnName: 'automotive_services',
            label: '汽车服务顾问',
            value: 'automotive_service_consultant',
          },
          {
            groupName: '汽车服务',
            groupEnName: 'automotive_services',
            label: '4S店店长/维修站长',
            value: '4s_store_manager_repair_station_manager',
          },
          {
            groupName: '汽车服务',
            groupEnName: 'automotive_services',
            label: '汽车查勘定损',
            value: 'auto_damage_assessment',
          },
          {
            groupName: '汽车服务',
            groupEnName: 'automotive_services',
            label: '加油员',
            value: 'gas_station_attendant',
          },
        ],
      },

      {
        label: '宠物服务',
        value: 'pet_services',
        children: [
          {
            label: '宠物美容',
            value: 'pet_grooming',
          },
          {
            groupName: '宠物服务',
            groupEnName: 'pet_services',
            label: '宠物医生',
            value: 'veterinarian',
          },
        ],
      },

      {
        label: '运动健身',
        value: 'fitness_sports',
        children: [
          {
            label: '健身教练',
            value: 'fitness_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '舞蹈老师',
            value: 'dance_instructor',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '体育教师/体育教练',
            value: 'physical_education_teacher_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '篮球教练',
            value: 'basketball_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '瑜伽老师',
            value: 'yoga_instructor',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '游泳教练',
            value: 'swimming_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '跆拳道教练',
            value: 'taekwondo_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '武术教练',
            value: 'martial_arts_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '轮滑教练',
            value: 'roller_skating_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '救生员',
            value: 'lifeguard',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '乒乓球教练',
            value: 'table_tennis_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '足球教练',
            value: 'football_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '羽毛球教练',
            value: 'badminton_coach',
          },
          {
            groupName: '运动健身',
            groupEnName: 'fitness_sports',
            label: '拳击教练',
            value: 'boxing_coach',
          },
        ],
      },

      {
        label: '驾驶员',
        value: 'drivers',
        children: [
          {
            label: '网约车司机',
            value: 'ride_hailing_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '代驾司机',
            value: 'designated_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '驾校教练',
            value: 'driving_school_instructor',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '商务司机',
            value: 'corporate_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '货运司机',
            value: 'freight_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '客运司机',
            value: 'passenger_transport_driver',
          },
          {
            groupName: '驾驶员',
            groupEnName: 'drivers',
            label: '无人机飞手',
            value: 'drone_pilot',
          },
        ],
      },

      {
        label: '其他服务业职位',
        value: 'other_service_positions',
        children: [
          {
            label: '花艺师',
            value: 'florist',
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '婚礼策划',
            value: 'wedding_planner',
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '网吧网管',
            value: 'internet_cafe_administrator',
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '会籍顾问',
            value: 'membership_consultant',
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '旅游顾问',
            value: 'travel_consultant',
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '验光师',
            value: 'optometrist',
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '摄影师/摄像师',
            value: 'photographer_videographer',
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '剧本杀主持人',
            value: 'murder_mystery_host',
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '儿童引导师',
            value: "children's_guide",
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '放映员',
            value: 'projectionist',
          },
          {
            groupName: '其他服务业职位',
            groupEnName: 'other_service_positions',
            label: '其他服务业职位',
            value: 'other_service_positions',
          },
        ],
      },
    ],
  },
  {
    label: '生产制造',
    value: 'Manufacturing',
    children: [
      {
        label: '普工',
        value: 'general_workers',
        children: [
          {
            label: '普工',
            value: 'general_worker',
          },
          {
            groupName: '普工',
            groupEnName: 'general_workers',
            label: '操作工',
            value: 'operator',
          },
          {
            groupName: '普工',
            groupEnName: 'general_workers',
            label: '包装工',
            value: 'packer',
          },
          {
            groupName: '普工',
            groupEnName: 'general_workers',
            label: '学徒工',
            value: 'apprentice',
          },
          {
            groupName: '普工',
            groupEnName: 'general_workers',
            label: '搬运工/装卸工',
            value: 'loader_unloader',
          },
          {
            groupName: '普工',
            groupEnName: 'general_workers',
            label: '组装工',
            value: 'assembler',
          },
        ],
      },

      {
        label: '机械加工',
        value: 'mechanical_processing',
        children: [
          {
            label: '焊工',
            value: 'welder',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '氩弧焊工',
            value: 'argon_arc_welder',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '车工',
            value: 'lathe_operator',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '钳工',
            value: 'fitter',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '切割工',
            value: 'cutter',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '钣金工',
            value: 'sheet_metal_worker',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '注塑工',
            value: 'injection_molder',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '折弯工',
            value: 'bender',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '磨工',
            value: 'grinder',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '模具工',
            value: 'mold_maker',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '铣工',
            value: 'miller',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '抛光工',
            value: 'polisher',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '喷塑工',
            value: 'plastic_sprayer',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '钻工',
            value: 'driller',
          },
          {
            groupName: '机械加工',
            groupEnName: 'mechanical_processing',
            label: '铆工',
            value: 'riveter',
          },
        ],
      },

      {
        label: '技工',
        value: 'skilled_workers',
        children: [
          {
            label: '电工',
            value: 'electrician',
          },
          {
            groupName: '技工',
            groupEnName: 'skilled_workers',
            label: '弱电工',
            value: 'weak_current_electrician',
          },
          {
            groupName: '技工',
            groupEnName: 'skilled_workers',
            label: '机修工',
            value: 'machine_repairman',
          },
          {
            groupName: '技工',
            groupEnName: 'skilled_workers',
            label: '木工',
            value: 'carpenter',
          },
          {
            groupName: '技工',
            groupEnName: 'skilled_workers',
            label: '油漆工',
            value: 'painter',
          },
          {
            groupName: '技工',
            groupEnName: 'skilled_workers',
            label: '锅炉工',
            value: 'boiler_operator',
          },
        ],
      },

      {
        label: '运输设备操作',
        value: 'transportation_equipment_operation',
        children: [
          {
            label: '叉车工',
            value: 'forklift_operator',
          },
          {
            groupName: '运输设备操作',
            groupEnName: 'transportation_equipment_operation',
            label: '铲车司机',
            value: 'shovel_driver',
          },
          {
            groupName: '运输设备操作',
            groupEnName: 'transportation_equipment_operation',
            label: '挖掘机司机',
            value: 'excavator_operator',
          },
        ],
      },
      {
        label: '质量管理',
        value: 'quality_management',
        children: [
          {
            label: '质量管理/测试',
            value: 'quality_management_and_testing',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '质检员',
            value: 'quality_inspector',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '实验室技术员',
            value: 'laboratory_technician',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '体系工程师',
            value: 'system_engineer',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '体系审核员',
            value: 'system_auditor',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '产品认证工程师',
            value: 'product_certification_engineer',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '失效分析工程师',
            value: 'failure_analysis_engineer',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '可靠度工程师',
            value: 'reliability_engineer',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '供应商质量工程师',
            value: 'supplier_quality_engineer',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '医疗器械生产/质量管理',
            value: 'medical_device_production_quality_management',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '汽车质量工程师',
            value: 'automotive_quality_engineer',
          },
          {
            groupName: '质量管理',
            groupEnName: 'quality_management',
            label: '计量工程师',
            value: 'metrology_engineer',
          },
        ],
      },

      {
        label: '机械设计/制造',
        value: 'mechanical_design_and_manufacturing',
        children: [
          {
            label: 'CNC/数控',
            value: 'CNC_programming_and_operation',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '机械工程师',
            value: 'mechanical_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '机械结构工程师',
            value: 'mechanical_structure_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '工艺工程师',
            value: 'process_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '机械设备工程师',
            value: 'mechanical_equipment_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '模具工程师',
            value: 'tooling_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '机械制图员',
            value: 'mechanical_drafting_technician',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '机电工程师',
            value: 'mechatronics_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '注塑工程师',
            value: 'injection_molding_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '夹具工程师',
            value: 'fixture_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '冲压工程师',
            value: 'stamping_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '铸造/锻造工程师',
            value: 'casting_forging_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '机械维修/保养',
            value: 'mechanical_maintenance_technician',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '材料工程师',
            value: 'materials_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '工业工程师(IE)',
            value: 'industrial_engineer_(IE)',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '焊接工程师',
            value: 'welding_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '热设计工程师',
            value: 'thermal_design_engineer',
          },
          {
            groupName: '机械设计/制造',
            groupEnName: 'mechanical_design_and_manufacturing',
            label: '液压工程师',
            value: 'hydraulic_engineer',
          },
        ],
      },

      {
        label: '电气/自动化',
        value: 'electrical_automation',
        children: [
          {
            label: '电气工程师',
            value: 'electrical_engineer',
          },
          {
            groupName: '电气/自动化',
            groupEnName: 'electrical_automation',
            label: '电气设计工程师',
            value: 'electrical_design_engineer',
          },
          {
            groupName: '电气/自动化',
            groupEnName: 'electrical_automation',
            label: '自动化',
            value: 'automation_engineer',
          },
        ],
      },

      {
        label: '生产营运',
        value: 'production_operations',
        children: [
          {
            label: '车间主任',
            value: 'workshop_director',
          },
          {
            groupName: '生产营运',
            groupEnName: 'production_operations',
            label: '生产组长拉长',
            value: 'production_team_leader',
          },
          {
            groupName: '生产营运',
            groupEnName: 'production_operations',
            label: '生产总监',
            value: 'production_director',
          },
          {
            groupName: '生产营运',
            groupEnName: 'production_operations',
            label: '厂长',
            value: 'factory_manager',
          },
          {
            groupName: '生产营运',
            groupEnName: 'production_operations',
            label: '生产跟单文员',
            value: 'production_order_clerk',
          },
          {
            groupName: '生产营运',
            groupEnName: 'production_operations',
            label: '生产计划/物料管理(PMC)',
            value: 'production_planning_and_materials_control_(PMC)',
          },
          {
            groupName: '生产营运',
            groupEnName: 'production_operations',
            label: '生产设备管理员',
            value: 'production_equipment_manager',
          },
          {
            groupName: '生产营运',
            groupEnName: 'production_operations',
            label: '厂务',
            value: 'factory_affairs_manager',
          },
        ],
      },

      {
        label: '生产安全',
        value: 'production_safety',
        children: [
          {
            label: '生产安全员',
            value: 'production_safety_officer',
          },
          {
            groupName: '生产安全',
            groupEnName: 'production_safety',
            label: '施工安全员',
            value: 'construction_safety_officer',
          },
          {
            groupName: '生产安全',
            groupEnName: 'production_safety',
            label: 'EHS工程师',
            value: 'EHS_engineer',
          },
          {
            groupName: '生产安全',
            groupEnName: 'production_safety',
            label: '安全评价师',
            value: 'safety_evaluator',
          },
        ],
      },

      {
        label: '化工',
        value: 'chemical_industry',
        children: [
          {
            label: '实验室技术员',
            value: 'laboratory_technician',
          },
          {
            groupName: '化工',
            groupEnName: 'chemical_industry',
            label: '化工工程师',
            value: 'chemical_engineer',
          },
          {
            groupName: '化工',
            groupEnName: 'chemical_industry',
            label: '食品/饮料研发',
            value: 'food_and_beverage_research_and_development',
          },
          {
            groupName: '化工',
            groupEnName: 'chemical_industry',
            label: '化妆品研发',
            value: 'cosmetics_research_and_development',
          },
          {
            groupName: '化工',
            groupEnName: 'chemical_industry',
            label: '化妆品产品经理',
            value: 'cosmetics_product_manager',
          },
          {
            groupName: '化工',
            groupEnName: 'chemical_industry',
            label: '涂料研发',
            value: 'paint_research_and_development',
          },
          {
            groupName: '化工',
            groupEnName: 'chemical_industry',
            label: '化工项目经理',
            value: 'chemical_project_manager',
          },
        ],
      },

      {
        label: '服装/纺织/皮革',
        value: 'apparel_textile_leather',
        children: [
          {
            label: '服装/纺织/皮革跟单',
            value: 'apparel_textile_leather_merchandiser',
          },
          {
            groupName: '服装/纺织/皮革',
            groupEnName: 'apparel_textile_leather',
            label: '打样/制版',
            value: 'pattern_making_and_sampling',
          },
          {
            groupName: '服装/纺织/皮革',
            groupEnName: 'apparel_textile_leather',
            label: '服装/纺织设计',
            value: 'apparel_textile_design',
          },
          {
            groupName: '服装/纺织/皮革',
            groupEnName: 'apparel_textile_leather',
            label: '面料辅料开发',
            value: 'fabric_and_accessories_development',
          },
          {
            groupName: '服装/纺织/皮革',
            groupEnName: 'apparel_textile_leather',
            label: '缝纫工',
            value: 'sewer',
          },
          {
            groupName: '服装/纺织/皮革',
            groupEnName: 'apparel_textile_leather',
            label: '样衣工',
            value: 'sample_maker',
          },
          {
            groupName: '服装/纺织/皮革',
            groupEnName: 'apparel_textile_leather',
            label: '量体师',
            value: 'tailor_measurer',
          },
          {
            groupName: '服装/纺织/皮革',
            groupEnName: 'apparel_textile_leather',
            label: '裁剪工',
            value: 'cutter',
          },
        ],
      },

      {
        label: '新能源汽车',
        value: 'new_energy_vehicles',
        children: [
          {
            label: '电池工程师',
            value: 'battery_engineer',
          },
          {
            groupName: '新能源汽车',
            groupEnName: 'new_energy_vehicles',
            label: '电机工程师',
            value: 'electric_motor_engineer',
          },
        ],
      },

      {
        label: '汽车研发/制造',
        value: 'automotive_r_and_d_manufacturing',
        children: [
          {
            label: '车身造型设计',
            value: 'vehicle_body_styling_design',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '汽车电子工程师',
            value: 'automotive_electronics_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '自动驾驶系统工程师',
            value: 'autonomous_driving_system_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '线束设计',
            value: 'wiring_harness_design',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '内外饰设计工程师',
            value: 'interior_and_exterior_designer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '动力系统工程师',
            value: 'powertrain_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '底盘工程师',
            value: 'chassis_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '汽车零部件设计',
            value: 'automotive_component_design',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '汽车设计',
            value: 'automotive_design',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '汽车质量工程师',
            value: 'automotive_quality_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '总装工程师',
            value: 'assembly_engineer',
          },
          {
            groupName: '汽车研发/制造',
            groupEnName: 'automotive_r_and_d_manufacturing',
            label: '汽车项目管理',
            value: 'automotive_project_management',
          },
        ],
      },

      {
        label: '环保',
        value: 'environmental_health_and_safety',
        children: [
          {
            label: 'EHS工程师',
            value: 'ehs_engineer',
          },
          {
            groupName: '环保',
            groupEnName: 'environmental_health_and_safety',
            label: '环境采样/检测员',
            value: 'environmental_sampling_and_testing_technician',
          },
          {
            groupName: '环保',
            groupEnName: 'environmental_health_and_safety',
            label: '环评工程师',
            value: 'environmental_impact_assessment_engineer',
          },
          {
            groupName: '环保',
            groupEnName: 'environmental_health_and_safety',
            label: '环保工程师',
            value: 'environmental_engineer',
          },
          {
            groupName: '环保',
            groupEnName: 'environmental_health_and_safety',
            label: '碳排放管理师',
            value: 'carbon_emission_manager',
          },
        ],
      },
    ],
  },
  {
    label: '电子/电气/通信',
    value: 'electronics/electrical/communication',
    children: [
      {
        label: '电子硬件开发',
        value: 'electronic_hardware_development',
        children: [
          {
            label: '电子工程师',
            value: 'electronics_engineer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: '硬件工程师',
            value: 'hardware_engineer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: '嵌入式软件工程师',
            value: 'embedded_software_engineer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: 'FPGA开发',
            value: 'fpga_developer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: '单片机',
            value: 'mcu_developer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: '驱动开发工程师',
            value: 'driver_development_engineer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: 'PCB工程师',
            value: 'pcb_engineer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: '电子维修技术员',
            value: 'electronics_maintenance_technician',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: '射频工程师',
            value: 'rf_engineer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: '电路设计',
            value: 'circuit_design',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: '系统集成',
            value: 'system_integration',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: '光学工程师',
            value: 'optical_engineer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: 'DSP开发',
            value: 'dsp_developer',
          },
          {
            groupName: '电子硬件开发',
            groupEnName: 'electronic_hardware_development',
            label: 'ARM开发',
            value: 'arm_developer',
          },
        ],
      },

      {
        label: '半导体芯片',
        value: 'semiconductor_chip',
        children: [
          {
            label: '集成电路IC设计',
            value: 'ic_design_engineer',
          },
          {
            groupName: '半导体芯片',
            groupEnName: 'semiconductor_chip',
            label: '数字IC验证工程师',
            value: 'digital_ic_verification_engineer',
          },
          {
            groupName: '半导体芯片',
            groupEnName: 'semiconductor_chip',
            label: '模拟版图设计工程师',
            value: 'analog_layout_designer',
          },
          {
            groupName: '半导体芯片',
            groupEnName: 'semiconductor_chip',
            label: '芯片测试工程师',
            value: 'chip_test_engineer',
          },
          {
            groupName: '半导体芯片',
            groupEnName: 'semiconductor_chip',
            label: 'DFT工程师',
            value: 'dft_engineer',
          },
          {
            groupName: '半导体芯片',
            groupEnName: 'semiconductor_chip',
            label: 'FAE',
            value: 'fae_field_application_engineer',
          },
          {
            groupName: '半导体芯片',
            groupEnName: 'semiconductor_chip',
            label: '数字前端设计师',
            value: 'digital_frontend_designer',
          },
          {
            groupName: '半导体芯片',
            groupEnName: 'semiconductor_chip',
            label: '数字后端工程师',
            value: 'digital_backend_engineer',
          },
          {
            groupName: '半导体芯片',
            groupEnName: 'semiconductor_chip',
            label: '模拟IC设计工程师',
            value: 'analog_ic_design_engineer',
          },
        ],
      },

      {
        label: '电气/自动化',
        value: 'electrical_automation',
        children: [
          { label: '电气工程师', value: 'electrical_engineer' },
          {
            groupName: '电气/自动化',
            groupEnName: 'electrical_automation',
            label: '电气设计工程师',
            value: 'electrical_design_engineer',
          },
          {
            groupName: '电气/自动化',
            groupEnName: 'electrical_automation',
            label: '自动化',
            value: 'automation_engineer',
          },
          {
            groupName: '电气/自动化',
            groupEnName: 'electrical_automation',
            label: '机电工程师',
            value: 'mechatronics_engineer',
          },
          {
            groupName: '电气/自动化',
            groupEnName: 'electrical_automation',
            label: '建筑机电工程师',
            value: 'building_mechatronics_engineer',
          },
        ],
      },

      {
        label: '技术项目管理',
        value: 'technical_project_management',
        children: [
          {
            label: '项目经理/主管',
            value: 'project_manager_supervisor',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '项目助理',
            value: 'project_assistant',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '项目专员',
            value: 'project_specialist',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '实施工程师',
            value: 'implementation_engineer',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '实施顾问',
            value: 'implementation_consultant',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '需求分析工程师',
            value: 'requirements_analysis_engineer',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '硬件项目经理',
            value: 'hardware_project_manager',
          },
        ],
      },

      {
        label: '通信',
        value: 'communication',
        children: [
          {
            label: '通信项目专员',
            value: 'communication_project_specialist',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '通信项目经理',
            value: 'communication_project_manager',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '通信技术工程师',
            value: 'communication_technology_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '通信研发工程师',
            value: 'communication_r_d_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '无线/射频通信工程师',
            value: 'wireless_rf_communication_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '移动通信工程师',
            value: 'mobile_communication_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '电信网络工程师',
            value: 'telecommunication_network_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '数据通信工程师',
            value: 'data_communication_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '通信测试工程师',
            value: 'communication_test_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '光通信工程师',
            value: 'optical_communication_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '光网络工程师',
            value: 'optical_network_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '通信电源工程师',
            value: 'communication_power_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '有线传输工程师',
            value: 'wired_transmission_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '通信设备工程师',
            value: 'communication_equipment_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '核心网工程师',
            value: 'core_network_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '通信标准化工程师',
            value: 'communication_standardization_engineer',
          },
          {
            groupName: '通信',
            groupEnName: 'communication',
            label: '网络工程师',
            value: 'network_engineer',
          },
        ],
      },

      {
        label: '销售技术支持',
        value: 'sales_technical_support',
        children: [
          {
            label: '售前技术支持',
            value: 'pre_sales_technical_support',
          },
          {
            groupName: '销售技术支持',
            groupEnName: 'sales_technical_support',
            label: '售后技术支持',
            value: 'after_sales_technical_support',
          },
          {
            groupName: '销售技术支持',
            groupEnName: 'sales_technical_support',
            label: '销售技术支持',
            value: 'sales_technical_support',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations_technical_support',
            label: '运维工程师',
            value: 'operations_engineer',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations_technical_support',
            label: '系统工程师',
            value: 'system_engineer',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations_technical_support',
            label: '技术文档工程师',
            value: 'technical_documentation_engineer',
          },
        ],
      },
    ],
  },
  {
    label: '客服/运营',
    value: 'customer_service/operations',
    children: [
      {
        label: '电商运营',
        value: 'e_commerce_operations',
        children: [
          {
            label: '国内电商运营',
            value: 'domestic_e_commerce_operations',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '跨境电商运营',
            value: 'cross_border_e_commerce_operations',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '品类运营',
            value: 'category_operations',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '淘宝运营',
            value: 'tao_bao_operations',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '天猫运营',
            value: 't_mall_operations',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '京东运营',
            value: 'jd_operations',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '拼多多运营',
            value: 'pin_duo_duo_operations',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '亚马逊运营',
            value: 'amazon_operations',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '亚马逊产品开发',
            value: 'amazon_product_development',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '阿里国际站运营',
            value: 'alibaba_international_operations',
          },
          {
            groupName: '电商运营',
            groupEnName: 'e_commerce_operations',
            label: '速卖通运营',
            value: 'ali_express_operations',
          },
        ],
      },

      {
        label: '业务运营',
        value: 'business_operations',
        children: [
          { label: '运营助理/专员', value: 'operations_assistant_specialist' },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '产品运营',
            value: 'product_operations',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '用户运营',
            value: 'user_operations',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '商家运营',
            value: 'merchant_operations',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '数据/策略运营',
            value: 'data_strategy_operations',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '社区运营',
            value: 'community_operations',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '游戏运营',
            value: 'game_operations',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '活动运营',
            value: 'event_operations',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '网站运营',
            value: 'website_operations',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '内容审核',
            value: 'content_review',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '数据标注/AI训练师',
            value: 'data_annotation_ai_trainer',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '线下运营',
            value: 'offline_operations',
          },
          {
            groupName: '业务运营',
            groupEnName: 'business_operations',
            label: '新媒体运营',
            value: 'new_Media_Operations',
          },
        ],
      },

      {
        label: '线下运营',
        value: 'offline_operations',
        children: [
          { label: '车辆运营', value: 'vehicle_operations' },
          {
            groupName: '线下运营',
            groupEnName: 'offline_operations',
            label: '线下拓展运营',
            value: 'offline_expansion_operations',
          },
          {
            groupName: '线下运营',
            groupEnName: 'offline_operations',
            label: '商场运营',
            value: 'mall_operations',
          },
        ],
      },
      {
        label: '编辑',
        value: 'editing',
        children: [
          { label: '文案编辑', value: 'copywriting_editor' },
          {
            groupName: '编辑',
            groupEnName: 'editing',
            label: '网站编辑',
            value: 'website_editor',
          },
          {
            groupName: '编辑',
            groupEnName: 'editing',
            label: '医学编辑',
            value: 'medical_editor',
          },
          {
            groupName: '编辑',
            groupEnName: 'editing',
            label: '主编/副主编',
            value: 'chief_editor_deputy_editor',
          },
        ],
      },
      {
        label: '高端运营职位',
        value: 'high_end_operational_positions',
        children: [
          { label: '运营经理/主管', value: 'operations_manager_supervisor' },
          {
            groupName: '高端运营职位',
            groupEnName: 'high_end_operational_positions',
            label: '运营总监',
            value: 'operations_director',
          },
          {
            groupName: '高端运营职位',
            groupEnName: 'high_end_operational_positions',
            label: 'COO',
            value: 'chief_operating_officer',
          },
          {
            groupName: '高端运营职位',
            groupEnName: 'high_end_operational_positions',
            label: '客服总监',
            value: 'customer_service_director',
          },
        ],
      },
    ],
  },

  {
    label: '产品',
    value: 'product',
    children: [
      {
        label: '产品经理',
        value: 'product_management',
        children: [
          {
            label: '产品经理',
            value: 'product_manager',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '产品专员/助理',
            value: 'product_specialist_assistant',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '高级产品管理岗',
            value: 'senior_product_management_position',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '电商产品经理',
            value: 'e_commerce_product_manager',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: 'AI产品经理',
            value: 'ai_product_manager',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '数据产品经理',
            value: 'data_product_manager',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '移动产品经理',
            value: 'mobile_product_manager',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '金融产品经理',
            value: 'financial_product_manager',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '亚马逊产品开发',
            value: 'amazon_product_development',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '化妆品产品经理',
            value: 'cosmetics_product_manager',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '硬件产品经理',
            value: 'hardware_product_manager',
          },
          {
            groupName: '产品经理',
            groupEnName: 'product_management',
            label: '其他产品职位',
            value: 'other_product_positions',
          },
        ],
      },

      {
        label: '游戏策划/制作',
        value: 'game_planning_production',
        children: [
          {
            label: '游戏策划',
            value: 'game_planner',
          },
          {
            groupName: '游戏策划/制作',
            groupEnName: 'game_planning_production',
            label: '系统策划',
            value: 'system_planner',
          },
          {
            groupName: '游戏策划/制作',
            groupEnName: 'game_planning_production',
            label: '游戏数值策划',
            value: 'game_numerics_planner',
          },
          {
            groupName: '游戏策划/制作',
            groupEnName: 'game_planning_production',
            label: '游戏制作人',
            value: 'game_producer',
          },
        ],
      },

      {
        label: '用户研究',
        value: 'user_research',
        children: [
          {
            label: '用户研究员',
            value: 'user_researcher',
          },
          {
            groupName: '用户研究',
            groupEnName: 'user_research',
            label: '用户研究经理',
            value: 'user_research_manager',
          },
          {
            groupName: '用户研究',
            groupEnName: 'user_research',
            label: '用户研究总监',
            value: 'user_research_director',
          },
        ],
      },
    ],
  },

  {
    label: '互联网/AI',
    value: 'internet/AI',
    children: [
      {
        label: '后端开发',
        value: 'backend_development',
        children: [
          { label: 'Java', value: 'Java' },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: 'C/C++',
            value: 'C/C++',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: 'PHP',
            value: 'PHP',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: 'Python',
            value: 'Python',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: 'C#',
            value: 'C#',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: '.NET',
            value: '.NET',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: 'Go',
            value: 'Golang',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: 'Node.js',
            value: 'Node.js',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: 'Hadoop',
            value: 'Hadoop',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: '语音/视频/图形开发',
            value: 'Voice/Video/graphics_development',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: 'GIS工程师',
            value: 'GIS_engineer',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: '区块链工程师',
            value: 'blockchain_engineer',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: '全栈工程师',
            value: 'full_stack_engineer',
          },
          {
            groupName: '后端开发',
            groupEnName: 'backend_development',
            label: '其他后端开发',
            value: 'other_backend_development',
          },
        ],
      },
      {
        label: '前端/移动开发',
        value: 'front_end/mobile_development',
        children: [
          {
            label: '前端开发工程师',
            value: 'frontend_development_engineer',
          },
          {
            groupName: '前端/移动开发',
            groupEnName: 'front_end/mobile_development',
            label: 'Android',
            value: 'Android',
          },
          {
            groupName: '前端/移动开发',
            groupEnName: 'front_end/mobile_development',
            label: 'iOS',
            value: 'iOS',
          },
          {
            groupName: '前端/移动开发',
            groupEnName: 'front_end/mobile_development',
            label: 'U3D',
            value: 'Unity 3D',
          },
          {
            groupName: '前端/移动开发',
            groupEnName: 'front_end/mobile_development',
            label: 'UE4',
            value: 'Unreal Engine 4',
          },
          {
            groupName: '前端/移动开发',
            groupEnName: 'front_end/mobile_development',
            label: 'Cocos',
            value: 'Cocos',
          },
          {
            groupName: '前端/移动开发',
            groupEnName: 'front_end/mobile_development',
            label: '技术美术',
            value: 'technical_artist',
          },
          {
            groupName: '前端/移动开发',
            groupEnName: 'front_end/mobile_development',
            label: 'JavaScript',
            value: 'JavaScript',
          },
          {
            groupName: '前端/移动开发',
            groupEnName: 'front_end/mobile_development',
            label: '鸿蒙开发工程师',
            value: 'HarmonyOS Development Engineer',
          },
        ],
      },
      {
        label: '测试',
        value: 'test',
        children: [
          {
            label: '测试工程师',
            value: 'test_engineer',
          },
          {
            groupName: '测试',
            groupEnName: 'test',
            label: '软件测试',
            value: 'software_testing',
          },
          {
            groupName: '测试',
            groupEnName: 'test',
            label: '自动化测试',
            value: 'automation_testing',
          },
          {
            groupName: '测试',
            groupEnName: 'test',
            label: '功能测试',
            value: 'functional_testing',
          },
          {
            groupName: '测试',
            groupEnName: 'test',
            label: '测试开发',
            value: 'test_development',
          },
          {
            groupName: '测试',
            groupEnName: 'test',
            label: '硬件测试',
            value: 'hardware_testing',
          },
          {
            groupName: '测试',
            groupEnName: 'test',
            label: '游戏测试',
            value: 'game_testing',
          },
          {
            groupName: '测试',
            groupEnName: 'test',
            label: '性能测试',
            value: 'performance_testing',
          },
          {
            groupName: '测试',
            groupEnName: 'test',
            label: '渗透测试',
            value: 'penetration_testing',
          },
          {
            groupName: '测试',
            groupEnName: 'test',
            label: '测试经理',
            value: 'test_manager',
          },
        ],
      },

      {
        label: '运维/技术支持',
        value: 'operations/technical_support',
        children: [
          { label: '运维工程师', value: 'operations_engineer' },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations/technical_support',
            label: 'IT技术支持',
            value: 'it_support',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations/technical_support',
            label: '网络工程师',
            value: 'network_engineer',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations/technical_support',
            label: '网络安全',
            value: 'network_security',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations/technical_support',
            label: '系统工程师',
            value: 'systems_engineer',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations/technical_support',
            label: '运维开发工程师',
            value: 'devops_engineer',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations/technical_support',
            label: '系统管理员',
            value: 'system_administrator',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations/technical_support',
            label: 'DBA (数据库管理员)',
            value: 'dba_database_administrator',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations/technical_support',
            label: '系统安全',
            value: 'system_security',
          },
          {
            groupName: '运维/技术支持',
            groupEnName: 'operations/technical_support',
            label: '技术文档工程师',
            value: 'technical_documentation_engineer',
          },
        ],
      },

      {
        label: '人工智能',
        value: 'artificial_intelligence',
        children: [
          { label: '图像算法', value: 'image_algorithm' },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '自然语言处理算法',
            value: 'natural_language_processing_algorithm',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '大模型算法',
            value: 'large_model_algorithm',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '数据挖掘',
            value: 'data_mining',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '规控算法',
            value: 'control_algorithm',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: 'SLAM算法',
            value: 'slam_algorithm',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '推荐算法',
            value: 'recommendation_algorithm',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '搜索算法',
            value: 'search_algorithm',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '语音算法',
            value: 'speech_algorithm',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '风控算法',
            value: 'risk_control_algorithm',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '算法研究员',
            value: 'algorithm_researcher',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '算法工程师',
            value: 'algorithm_engineer',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '机器学习',
            value: 'machine_learning',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '深度学习',
            value: 'deep_learning',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '自动驾驶系统工程师',
            value: 'autonomous_driving_system_engineer',
          },
          {
            groupName: '人工智能',
            groupEnName: 'artificial_intelligence',
            label: '数据标注/A训练师',
            value: 'data_annotation_a_trainer',
          },
        ],
      },
      {
        label: '销售技术支持',
        value: 'sales_technical_support',
        children: [
          {
            label: '销售技术支持',
            value: 'sales_technical_support',
          },
          {
            groupName: '销售技术支持',
            groupEnName: 'sales_technical_support',
            label: '售前技术支持',
            value: 'pre_sales_technical_support',
          },
          {
            groupName: '销售技术支持',
            groupEnName: 'sales_technical_support',
            label: '售后技术支持',
            value: 'after_sales_technical_support',
          },
          {
            groupName: '销售技术支持',
            groupEnName: 'sales_technical_support',
            label: '客户成功',
            value: 'customer_success',
          },
        ],
      },

      {
        label: '数据',
        value: 'data',
        children: [
          { label: '数据分析师', value: 'data_analyst' },
          {
            groupName: '数据',
            groupEnName: 'data',
            label: '数据开发',
            value: 'data_development',
          },
          {
            groupName: '数据',
            groupEnName: 'data',
            label: '数据仓库',
            value: 'data_warehouse',
          },
          {
            groupName: '数据',
            groupEnName: 'data',
            label: 'ETL工程师',
            value: 'etl_engineer',
          },
          {
            groupName: '数据',
            groupEnName: 'data',
            label: '数据挖掘',
            value: 'data_mining',
          },
          {
            groupName: '数据',
            groupEnName: 'data',
            label: '数据架构师',
            value: 'data_architect',
          },
          {
            groupName: '数据',
            groupEnName: 'data',
            label: '爬虫工程师',
            value: 'web_crawler_engineer',
          },
          {
            groupName: '数据',
            groupEnName: 'data',
            label: '数据采集',
            value: 'data_collection',
          },
        ],
      },

      {
        label: '技术项目管理',
        value: 'technical_project_management',
        children: [
          { label: '项目经理/主管', value: 'project_manager_supervisor' },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '项目助理',
            value: 'project_assistant',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '项目专员',
            value: 'project_specialist',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '实施工程师',
            value: 'implementation_engineer',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '实施顾问',
            value: 'implementation_consultant',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '需求分析工程师',
            value: 'requirements_analysis_engineer',
          },
          {
            groupName: '技术项目管理',
            groupEnName: 'technical_project_management',
            label: '硬件项目经理',
            value: 'hardware_project_manager',
          },
        ],
      },

      {
        label: '高端技术职位',
        value: 'high_end_technical_positions',
        children: [
          {
            label: '技术经理',
            value: 'technical_manager',
          },
          {
            groupName: '高端技术职位',
            groupEnName: 'high_end_technical_positions',
            label: '架构师',
            value: 'architect',
          },
          {
            groupName: '高端技术职位',
            groupEnName: 'high_end_technical_positions',
            label: '技术总监',
            value: 'cto_technical_director',
          },
          {
            groupName: '高端技术职位',
            groupEnName: 'high_end_technical_positions',
            label: 'CTO/CIO',
            value: 'cto_cio',
          },
          {
            groupName: '高端技术职位',
            groupEnName: 'high_end_technical_positions',
            label: '技术合伙人',
            value: 'technical_partner',
          },
          {
            groupName: '高端技术职位',
            groupEnName: 'high_end_technical_positions',
            label: '运维总监',
            value: 'operation_and_maintenance_director',
          },
        ],
      },
    ],
  },

  // {
  //   label: '金融',
  //   value: 'finance_jr1',
  //   children: [
  //     {
  //       label: '融资计划书及报告',
  //       value: 'finance_jrc1',
  //     },
  //     {
  //       label: '投资前尽调',
  //       value: 'finance_jrc2',
  //     },
  //     {
  //       label: '融资谈判',
  //       value: 'finance_jrc3',
  //     },
  //     {
  //       label: '融资股权架构及财务模型',
  //       value: 'finance_jrc4',
  //     },
  //     {
  //       label: '估值模型',
  //       value: 'finance_jrc5',
  //     },
  //     {
  //       label: '投融并购流程及文档管理',
  //       value: 'finance_jrc6',
  //     },
  //   ],
  // },
  // {
  //   label: '会计',
  //   value: 'accountant',
  //   children: [
  //     {
  //       label: '应收会计',
  //       value: 'accountant_kjc1',
  //     },
  //     {
  //       label: '应付会计',
  //       value: 'accountant_kjc2',
  //     },
  //     {
  //       label: '总账会计',
  //       value: 'accountant_kjc3',
  //     },
  //     {
  //       label: '税务申报',
  //       value: 'accountant_kjc4',
  //     },
  //   ],
  // },
  // {
  //   label: '人事',
  //   value: 'accountant_rs1',
  //   children: [
  //     {
  //       label: '人事类培训',
  //       value: 'accountant_rsc1',
  //     },
  //     {
  //       label: '财务类培训',
  //       value: 'accountant_rsc2',
  //     },
  //     {
  //       label: '法务类培训',
  //       value: 'accountant_rsc3',
  //     },
  //   ],
  // },
];
export const specialDataEnglish = [
  {
    label: '财务financial',
    value: 'financial',
    children: [
      {
        label: '进销存管理',
        value: 'financial_purchase_sales_inventory_management',
      },
      {
        label: '财务控制',
        value: 'financial_Control',
      },
      {
        label: '财务报告及决策分析',
        value: 'financial_reporting_decision_analysis',
      },
      {
        label: '项目管控',
        value: 'financial_project_control',
      },
      {
        label: '财务模型设计及管理',
        value: 'financial_model_design_management',
      },
      {
        label: '成本及支出分析管理',
        value: 'financial_Cost_expenditure_analysis_management',
      },
      {
        label: '公司资产金融',
        value: 'financial_corporate_asset_financial',
      },
      {
        label: '内部风险管控',
        value: 'financial_Internal_risk_control',
      },
      {
        label: '财务预算规划及预测',
        value: 'financial_124',
      },
    ],
  },
  {
    label: '金融',
    value: 'finance_jr1',
    children: [
      {
        label: '融资计划书及报告',
        value: 'finance_jrc1',
      },
      {
        label: '投资前尽调',
        value: 'finance_jrc2',
      },
      {
        label: '融资谈判',
        value: 'finance_jrc3',
      },
      {
        label: '融资股权架构及财务模型',
        value: 'finance_jrc4',
      },
      {
        label: '估值模型',
        value: 'finance_jrc5',
      },
      {
        label: '投融并购流程及文档管理',
        value: 'finance_jrc6',
      },
    ],
  },
  {
    label: '会计',
    value: 'accountant',
    children: [
      {
        label: '应收会计',
        value: 'accountant_kjc1',
      },
      {
        label: '应付会计',
        value: 'accountant_kjc2',
      },
      {
        label: '总账会计',
        value: 'accountant_kjc3',
      },
      {
        label: '税务申报',
        value: 'accountant_kjc4',
      },
    ],
  },
  {
    label: '人事',
    value: 'accountant_rs1',
    children: [
      {
        label: '人事类培训',
        value: 'accountant_rsc1',
      },
      {
        label: '财务类培训',
        value: 'accountant_rsc2',
      },
      {
        label: '法务类培训',
        value: 'accountant_rsc3',
      },
    ],
  },
];
export default specialData;

export interface ITrainingCategory {
  label: string;
  value: string;
  children?: ITrainingItem[]; // 使用可选链操作符(?)来表明children可能不存在
}
export interface ITrainingItem {
  label: string;
  value: string;
  groupName?: string;
  groupEnName?: string;
}
