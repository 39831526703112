const LANGUAGE_WORLD = [
  {
    value: 'Chinese',
    label: '汉语普通话',
  },
  {
    value: 'English',
    label: '英语',
  },
  {
    value: 'Spanish',
    label: '西班牙语',
  },
  {
    value: 'Arabic',
    label: '阿拉伯语',
  },
  {
    value: 'Hindi',
    label: '印地语',
  },
  {
    value: 'Portuguese',
    label: '葡萄牙语',
  },
  {
    value: 'Russian',
    label: '俄语',
  },
  {
    value: 'Japanese',
    label: '日语',
  },
  {
    value: 'German',
    label: '德语',
  },
  {
    value: 'French',
    label: '法语',
  },
  {
    value: 'Turkish',
    label: '土耳其语',
  },
  {
    value: 'Italian',
    label: '意大利语',
  },
  {
    value: 'Korean',
    label: '韩语',
  },
  {
    value: 'Vietnamese',
    label: '越南语',
  },
  {
    value: 'Polish',
    label: '波兰语',
  },
  {
    value: 'Ukrainian',
    label: '乌克兰语',
  },
  {
    value: 'Dutch',
    label: '荷兰语',
  },
  {
    value: 'Swedish',
    label: '瑞典语',
  },
  {
    value: 'Norwegian',
    label: '挪威语',
  },
  {
    value: 'Danish',
    label: '丹麦语',
  },
  {
    value: 'Greek',
    label: '希腊语',
  },
  {
    value: 'Czech',
    label: '捷克语',
  },
  {
    value: 'Hungarian',
    label: '匈牙利语',
  },
  {
    value: 'Romanian',
    label: '罗马尼亚语',
  },
  {
    value: 'Finnish',
    label: '芬兰语',
  },
  {
    value: 'Bulgarian',
    label: '保加利亚语',
  },
  {
    value: 'Serbian',
    label: '塞尔维亚语',
  },
  {
    value: 'Croatian',
    label: '克罗地亚语',
  },
  {
    value: 'Slovak',
    label: '斯洛伐克语',
  },
  {
    value: 'Lithuanian',
    label: '立陶宛语',
  },
  {
    value: 'Slovenian',
    label: '斯洛文尼亚语',
  },
  {
    value: 'Estonian',
    label: '爱沙尼亚语',
  },
  {
    value: 'Latvian',
    label: '拉脱维亚语',
  },
  {
    value: 'Armenian',
    label: '亚美尼亚语',
  },
  {
    value: 'Albanian',
    label: '阿尔巴尼亚语',
  },
  {
    value: 'Georgian',
    label: '格鲁吉亚语',
  },
  {
    value: 'Macedonian',
    label: '马其顿语',
  },
  {
    value: 'Catalan',
    label: '加泰罗尼亚语',
  },
  {
    value: 'Basque',
    label: '巴斯克语',
  },
  {
    value: 'Galician',
    label: '加里西亚语',
  },
  {
    value: 'Maltese',
    label: '马耳他语',
  },
  {
    value: 'Irish',
    label: '爱尔兰语',
  },
  {
    value: 'Welsh',
    label: '威尔士语',
  },
  {
    value: 'Scottish Gaelic',
    label: '苏格兰盖尔语',
  },
  {
    value: 'Kurdish',
    label: '库尔德语',
  },
  {
    value: 'Amharic',
    label: '阿姆哈拉语',
  },
  {
    value: 'Somali',
    label: '索马里语',
  },
  {
    value: 'Mongolian',
    label: '蒙古语',
  },
  {
    value: 'Bengali',
    label: '孟加拉语',
  },
  {
    value: 'Thai',
    label: '泰语',
  },
  {
    value: 'Lao',
    label: '老挝语',
  },
  {
    value: 'Khmer',
    label: '柬埔寨语',
  },
  {
    value: 'Burmese',
    label: '缅甸语',
  },
  {
    value: 'Nepali',
    label: '尼泊尔语',
  },
  {
    value: 'Indonesian',
    label: '印尼语',
  },
  {
    value: 'Malay',
    label: '马来语',
  },
  {
    value: 'Swahili',
    label: '斯瓦希里语',
  },
  {
    value: 'Zulu',
    label: '祖鲁语',
  },
  {
    value: 'Hausa',
    label: '豪萨语',
  },
  {
    value: 'Azerbaijani',
    label: '阿塞拜疆语',
  },
  {
    value: 'Crimean Tatar',
    label: '克里米亚鞑靼语',
  },
  {
    value: 'Quechua',
    label: '克丘亚语',
  },
  {
    value: 'Lingala',
    label: '林加拉语',
  },
  {
    value: 'Luxembourgish',
    label: '卢森堡语',
  },
  {
    value: 'Mandingo',
    label: '曼丁哥语',
  },
  {
    value: 'Manx',
    label: '曼西语',
  },
  {
    value: 'Mossi',
    label: '莫西语',
  },
  {
    value: 'Navajo',
    label: '纳瓦约语',
  },
  {
    value: 'Oriya',
    label: '奥利亚语',
  },
  {
    value: 'Ossetian',
    label: '奥塞梯语',
  },
  {
    value: 'Oshiwambo',
    label: '奥信语',
  },
  {
    value: 'Quechua',
    label: '奇切瓦语',
  },
  {
    value: 'Sardinian',
    label: '萨丁语',
  },
  {
    value: 'Serbo-Croatian',
    label: '塞尔维亚克罗地亚语',
  },
  {
    value: 'Tagalog',
    label: '塔加洛语',
  },
  {
    value: 'Telugu',
    label: '泰卢固语',
  },
  {
    value: 'Tigrinya',
    label: '提格里尼亚语',
  },
  {
    value: 'Uyghur',
    label: '维吾尔语',
  },
  {
    value: 'Wolof',
    label: '瓦伦达语',
  },
  {
    value: 'Silesian',
    label: '西里西亚语',
  },
  {
    value: 'Nauruan',
    label: '瑙鲁语',
  },
];
export default LANGUAGE_WORLD;
