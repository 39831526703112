const educationBackground = [
  {
    value: 'Doctoral',
    label: '博士',
  },
  {
    value: 'Master',
    label: '硕士',
  },
  {
    value: 'Bachelor',
    label: '学士',
  },
  {
    value: 'Diploma',
    label: '大专',
  },
  {
    value: 'VocationalSchool',
    label: '中专/高中',
  },
  {
    value: 'MiddleSchool',
    label: '初中/及以下',
  },
];
export const educationBackgroundRequirements = [
  {
    value: 'Doctoral',
    label: '不限',
  },
  {
    value: 'Master',
    label: '大专及以上',
  },
  {
    value: 'Bachelor',
    label: '本科及以上',
  },
  {
    value: 'Diploma',
    label: '硕士及以上',
  },
];
export default educationBackground;
