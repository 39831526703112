export const CertificateTypeArray = [
  // {
  //   value: 'Accounting Certificate',
  //   label: '----------会计证书----------',
  //   disabled: true,
  //   enValue: 'Accounting Certificate',
  // },

  {
    value: '010101',
    label: '会计类证书',
    disabled: false,
    enValue: 'Accounting Certificate',
  },
  {
    value: '010102',
    label: '人事类证书',
    enValue: 'HR Certificate',
  },
  {
    value: '010103',
    label: '财务类证书',
    enValue: 'Finance Certificate',
  },
  {
    value: '010104',
    label: '法务类证书',
    enValue: 'Legal Certificate',
  },
  {
    value: '010105',
    label: '金融类证书',
    enValue: 'Banking and Finance Certificate',
  },
  {
    value: '010106',
    label: '市场营销类证书',
    enValue: 'Marketing Certificate',
  },
  {
    value: '010107',
    label: '计算机类证书',
    enValue: 'IT Certificate',
  },
  {
    value: '010108',
    label: '语言类证书',
    enValue: 'Language Certificate',
  },
  {
    value: '010109',
    label: '职业培训类证书',
    enValue: 'Vocational Training Certificate',
  },
  {
    value: '010110',
    label: '医生类证书',
    enValue: 'Medical Certificate',
  },
  {
    value: '010111',
    label: '建筑师类证书',
    enValue: 'Architecture Certificate',
  },
  {
    value: '010112',
    label: '工程类类证书',
    enValue: 'Engineering Certificate',
  },
];
export const CertificateTypeArrayEn = [
  {
    value: '010101',
    label: 'Accounting Certificate',
    disabled: false,
    enValue: 'Accounting Certificate',
  },
  {
    value: '010102',
    label: 'HR Certificate',
    enValue: 'HR Certificate',
  },
  {
    value: '010103',
    label: 'Finance Certificate',
    enValue: 'Finance Certificate',
  },
  {
    value: '010104',
    label: 'Legal Certificate',
    enValue: 'Legal Certificate',
  },
  {
    value: '010105',
    label: 'Banking and Finance Certificate',
    enValue: 'Banking and Finance Certificate',
  },
  {
    value: '010106',
    label: 'Marketing Certificate',
    enValue: 'Marketing Certificate',
  },
  {
    value: '010107',
    label: 'IT Certificate',
    enValue: 'IT Certificate',
  },
  {
    value: '010108',
    label: 'Language Certificate',
    enValue: 'Language Certificate',
  },
  {
    value: '010109',
    label: 'Vocational Training Certificate',
    enValue: 'Vocational Training Certificate',
  },
  {
    value: '010110',
    label: 'Medical Certificate',
    enValue: 'Medical Certificate',
  },
  {
    value: '010111',
    label: 'Architecture Certificate',
    enValue: 'Architecture Certificate',
  },
  {
    value: '010112',
    label: 'Engineering Certificate',
    enValue: 'Engineering Certificate',
  },
];
export default CertificateTypeArray;
