const specializedBackground = [
  { value: '', label: '----------哲学----------', disabled: true },
  { value: '010101', label: '哲学' },
  { value: '010102', label: '逻辑学' },
  { value: '010103K', label: '宗教学' },
  { value: '010104T', label: '伦理学' },
  { value: '', label: '----------经济学----------', disabled: true },
  { value: '010201', label: '经济学' },
  { value: '010202', label: '经济统计学' },
  { value: '010203T', label: '国民经济管理' },
  { value: '010204T', label: '资源与环境经济学' },
  { value: '010205T', label: '商务经济学' },
  { value: '010206T', label: '能源经济' },
  { value: '010207T', label: '劳动经济学' },
  { value: '010208T', label: '经济工程' },
  { value: '010209T', label: '数字经济' },

  { value: '020301K', label: '金融学' },
  { value: '020302', label: '金融工程' },
  { value: '020303', label: '保险学' },
  { value: '020304', label: '投资学' },
  { value: '020305T', label: '金融数学' },
  { value: '020306T', label: '信用管理' },
  { value: '020307T', label: '经济与金融' },
  { value: '020308T', label: '精算学' },
  { value: '020309T', label: '互联网金融' },
  { value: '020310T', label: '金融科技' },
  { value: '020311TK', label: '金融审计' },

  { value: '020401', label: '国际经济与贸易' },
  { value: '020402', label: '贸易经济' },
  { value: '020403T', label: '国际经济发展合作' },
  { value: '', label: '----------法学----------', disabled: true },
  { value: '030101K', label: '法学' },
  { value: '030102T', label: '知识产权' },
  { value: '030103T', label: '监狱学' },
  { value: '030104T', label: '信用风险管理与法律防控' },
  { value: '030105T', label: '国际经贸规则' },
  { value: '030106TK', label: '司法警察学' },
  { value: '030107TK', label: '社区矫正' },
  { value: '030108TK', label: '纪检监察' },
  { value: '030109TK', label: '国际法' },
  { value: '030110TK', label: '司法鉴定学' },

  { value: '030201', label: '政治学与行政学' },
  { value: '030202', label: '国际政治' },
  { value: '030203', label: '外交学' },
  { value: '030204T', label: '国际事务与国际关系' },
  { value: '030205T', label: '政治学、经济学与哲学' },
  { value: '030206TK', label: '国际组织与全球治理' },

  { value: '030301', label: '社会学' },
  { value: '030302', label: '社会工作' },
  { value: '030303T', label: '人类学' },
  { value: '030304T', label: '女性学' },
  { value: '030305T', label: '家政学' },
  { value: '030306T', label: '老年学' },
  { value: '030307T', label: '社会政策' },

  { value: '030401', label: '民族学' },

  { value: '030501', label: '科学社会主义' },
  { value: '030502', label: '中国共产党历史' },
  { value: '030503', label: '思想政治教育' },
  { value: '030504T', label: '马克思主义理论' },
  { value: '030505TK', label: '工会学' },

  { value: '030601K', label: '治安学' },
  { value: '030602K', label: '侦查学' },
  { value: '030603K', label: '边防管理' },
  { value: '030604TK', label: '禁毒学' },
  { value: '030605TK', label: '警犬技术' },
  { value: '030606TK', label: '经济犯罪侦查' },
  { value: '030607TK', label: '边防指挥' },
  { value: '030608TK', label: '消防指挥' },
  { value: '030609TK', label: '警卫学' },
  { value: '030610TK', label: '公安情报学' },
  { value: '030611TK', label: '犯罪学' },
  { value: '030612TK', label: '公安管理学' },
  { value: '030613TK', label: '涉外警务' },
  { value: '030614TK', label: '国内安全保卫' },
  { value: '030615TK', label: '警务指挥与战术' },
  { value: '030616TK', label: '技术侦查学' },
  { value: '030617TK', label: '海警执法' },
  { value: '030618TK', label: '公安政治工作' },
  { value: '030619TK', label: '移民管理' },
  { value: '030620TK', label: '出入境管理' },
  { value: '030621TK', label: '反恐警务' },
  { value: '030622TK', label: '消防政治工作' },
  { value: '030623TK', label: '铁路警务' },
  { value: '', label: '----------教育学----------', disabled: true },
  { value: '040101', label: '教育学' },
  { value: '040102', label: '科学教育' },
  { value: '040103', label: '人文教育' },
  { value: '040104', label: '教育技术学' },
  { value: '040105', label: '艺术教育' },
  { value: '040106', label: '学前教育' },
  { value: '040107', label: '小学教育' },
  { value: '040108', label: '特殊教育' },
  { value: '040109T', label: '华文教育' },
  { value: '040110TK', label: '教育康复学' },
  { value: '040111T', label: '卫生教育' },
  { value: '040112T', label: '认知科学与技术' },
  { value: '040113T', label: '融合教育' },
  { value: '040114TK', label: '劳动教育' },
  { value: '040115T', label: '家庭教育' },
  { value: '040116TK', label: '孤独症儿童教育' },

  { value: '040201', label: '体育教育' },
  { value: '040202K', label: '运动训练' },
  { value: '040203', label: '社会体育指导与管理' },
  { value: '040204K', label: '武术与民族传统体育' },
  { value: '040205', label: '运动人体科学' },
  { value: '040206T', label: '运动康复' },
  { value: '040207T', label: '休闲体育' },
  { value: '040208T', label: '体能训练' },
  { value: '040209T', label: '冰雪运动' },
  { value: '040210TK', label: '电子竞技运动与管理' },
  { value: '040211TK', label: '智能体育工程' },
  { value: '040212T', label: '体育旅游' },
  { value: '040213T', label: '运动能力开发' },
  { value: '', label: '----------文学----------', disabled: true },
  { value: '050101', label: '汉语言文学' },
  { value: '050102', label: '汉语言' },
  { value: '050103', label: '汉语国际教育' },
  { value: '050104', label: '中国少数民族语言文学' },
  { value: '050105', label: '古典文献学' },
  { value: '050106T', label: '应用语言学' },
  { value: '050107T', label: '秘书学' },
  { value: '050108T', label: '中国语言与文化' },
  { value: '050109T', label: '手语翻译' },
  { value: '050110T', label: '数字人文' },

  { value: '050201', label: '英语' },
  { value: '050202', label: '俄语' },
  { value: '050203', label: '德语' },
  { value: '050204', label: '法语' },
  { value: '050205', label: '西班牙语' },
  { value: '050206', label: '阿拉伯语' },
  { value: '050207', label: '日语' },
  { value: '050208', label: '波斯语' },
  { value: '050209', label: '朝鲜语' },
  { value: '050210', label: '菲律宾语' },
  { value: '050211', label: '梵语巴利语' },
  { value: '050212', label: '印度尼西亚语' },
  { value: '050213', label: '印地语' },
  { value: '050214', label: '柬埔寨语' },
  { value: '050215', label: '老挝语' },
  { value: '050216', label: '缅甸语' },
  { value: '050217', label: '马来语' },
  { value: '050218', label: '蒙古语' },
  { value: '050219', label: '僧伽罗语' },
  { value: '050220', label: '泰语' },
  { value: '050221', label: '乌尔都语' },
  { value: '050222', label: '希伯来语' },
  { value: '050223', label: '越南语' },
  { value: '050224', label: '豪萨语' },
  { value: '050225', label: '斯瓦希里语' },
  { value: '050226', label: '阿尔巴尼亚语' },
  { value: '050227', label: '保加利亚语' },
  { value: '050228', label: '波兰语' },
  { value: '050229', label: '捷克语' },
  { value: '050230', label: '斯洛伐克语' },
  { value: '050231', label: '罗马尼亚语' },
  { value: '050232', label: '葡萄牙语' },
  { value: '050233', label: '瑞典语' },
  { value: '050234', label: '塞尔维亚语' },
  { value: '050235', label: '土耳其语' },
  { value: '050236', label: '希腊语' },
  { value: '050237', label: '匈牙利语' },
  { value: '050238', label: '意大利语' },
  { value: '050239', label: '泰米尔语' },
  { value: '050240', label: '普什图语' },
  { value: '050241', label: '世界语' },
  { value: '050242', label: '孟加拉语' },
  { value: '050243', label: '尼泊尔语' },
  { value: '050244', label: '克罗地亚语' },
  { value: '050245', label: '荷兰语' },
  { value: '050246', label: '芬兰语' },
  { value: '050247', label: '乌克兰语' },
  { value: '050248', label: '挪威语' },
  { value: '050249', label: '丹麦语' },
  { value: '050250', label: '冰岛语' },
  { value: '050251', label: '爱尔兰语' },
  { value: '050252', label: '拉脱维亚语' },
  { value: '050253', label: '立陶宛语' },
  { value: '050254', label: '斯洛文尼亚语' },
  { value: '050255', label: '爱沙尼亚语' },
  { value: '050256', label: '马耳他语' },
  { value: '050257', label: '哈萨克语' },
  { value: '050258', label: '乌兹别克语' },
  { value: '050259', label: '祖鲁语' },
  { value: '050260', label: '拉丁语' },
  { value: '050261', label: '翻译' },
  { value: '050262', label: '商务英语' },
  { value: '050263T', label: '阿姆哈拉语' },
  { value: '050264T', label: '吉尔吉斯语' },
  { value: '050265T', label: '索马里语' },
  { value: '050266T', label: '土库曼语' },
  { value: '050267T', label: '加泰罗尼亚语' },
  { value: '050268T', label: '约鲁巴语' },
  { value: '050269T', label: '亚美尼亚语' },
  { value: '050270T', label: '马达加斯加语' },
  { value: '050271T', label: '格鲁吉亚语' },
  { value: '050272T', label: '阿塞拜疆语' },
  { value: '050273T', label: '阿非利卡语' },
  { value: '050274T', label: '马其顿语' },
  { value: '050275T', label: '塔吉克语' },
  { value: '050276T', label: '茨瓦纳语' },
  { value: '050277T', label: '恩德贝莱语' },
  { value: '050278T', label: '科摩罗语' },
  { value: '050279T', label: '克里奥尔语' },
  { value: '050280T', label: '绍纳语' },
  { value: '050281T', label: '提格雷尼亚语' },
  { value: '050282T', label: '白俄罗斯语' },
  { value: '050283T', label: '毛利语' },
  { value: '050284T', label: '汤加语' },
  { value: '050285T', label: '萨摩亚语' },
  { value: '050286T', label: '库尔德语' },
  { value: '050287T', label: '比斯拉马语' },
  { value: '050288T', label: '达里语' },
  { value: '050289T', label: '德顿语' },
  { value: '050290T', label: '迪维希语' },
  { value: '050291T', label: '斐济语' },
  { value: '050292T', label: '库克群岛毛利语' },
  { value: '050293T', label: '隆迪语' },
  { value: '050294T', label: '卢森堡语' },
  { value: '050295T', label: '卢旺达语' },
  { value: '050296T', label: '纽埃语' },
  { value: '050297T', label: '皮金语' },
  { value: '050298T', label: '切瓦语' },
  { value: '050299T', label: '塞苏陀语' },
  { value: '050200T', label: '桑戈语' },
  { value: '0502100T', label: '语言学' },
  { value: '0502101T', label: '塔玛齐格特语' },
  { value: '0502102T', label: '爪哇语' },
  { value: '0502103T', label: '旁遮普语' },

  { value: '050301', label: '新闻学' },
  { value: '050302', label: '广播电视学' },
  { value: '050303', label: '广告学' },
  { value: '050304', label: '传播学' },
  { value: '050305', label: '编辑出版学' },
  { value: '050306T', label: '网络与新媒体' },
  { value: '050307T', label: '数字出版' },
  { value: '050308T', label: '时尚传播' },
  { value: '050309T', label: '国际新闻与传播' },
  { value: '050310T', label: '会展' },
  { value: '', label: '----------历史学----------', disabled: true },
  { value: '060101', label: '历史学' },
  { value: '060102', label: '世界史' },
  { value: '060103', label: '考古学' },
  { value: '060104', label: '文物与博物馆学' },
  { value: '060105T', label: '文物保护技术' },
  { value: '060106T', label: '外国语言与外国历史' },
  { value: '060107T', label: '文化遗产' },
  { value: '060108T', label: '古文字学' },
  { value: '060109T', label: '科学史' },
  { value: '', label: '----------理学----------', disabled: true },
  { value: '070101', label: '数学与应用数学' },
  { value: '070102', label: '信息与计算科学' },
  { value: '070103T', label: '数理基础科学' },
  { value: '070104T', label: '数据计算及应用' },

  { value: '070201', label: '物理学' },
  { value: '070202', label: '应用物理学' },
  { value: '070203', label: '核物理' },
  { value: '070204T', label: '声学' },
  { value: '070205T', label: '系统科学与工程' },
  { value: '070206T', label: '量子信息科学' },

  { value: '070301', label: '化学' },
  { value: '070302', label: '应用化学' },
  { value: '070303T', label: '化学生物学' },
  { value: '070304T', label: '分子科学与工程' },
  { value: '070305T', label: '能源化学' },
  { value: '070306T', label: '化学测量学与技术' },
  { value: '070307T', label: '资源化学' },

  { value: '070401', label: '天文学' },

  { value: '070501', label: '地理科学' },
  { value: '070502', label: '自然地理与资源环境' },
  { value: '070503', label: '人文地理与城乡规划' },
  { value: '070504', label: '地理信息科学' },

  { value: '070601', label: '大气科学' },
  { value: '070602', label: '应用气象学' },
  { value: '070603T', label: '气象技术与工程' },
  { value: '070604T', label: '地球系统科学' },

  { value: '070701', label: '海洋科学' },
  { value: '070702', label: '海洋技术' },
  { value: '070703T', label: '海洋资源与环境' },
  { value: '070704T', label: '军事海洋学' },

  { value: '070801', label: '地球物理学' },
  { value: '070802', label: '空间科学与技术' },
  { value: '070803T', label: '防灾减灾科学与工程' },
  { value: '070804TK', label: '行星科学' },

  { value: '070901', label: '地质学' },
  { value: '070902', label: '地球化学' },
  { value: '070903T', label: '地球信息科学与技术' },
  { value: '070904T', label: '古生物学' },

  { value: '071001', label: '生物科学' },
  { value: '071002', label: '生物技术' },
  { value: '071003', label: '生物信息学' },
  { value: '071004', label: '生态学' },
  { value: '071005T', label: '整合科学' },
  { value: '071006T', label: '神经科学' },

  { value: '071101', label: '心理学' },
  { value: '071102', label: '应用心理学' },

  { value: '071201', label: '统计学' },
  { value: '071202', label: '应用统计学' },
  { value: '071203T', label: '数据科学' },
  { value: '071204T', label: '生物统计学' },
  { value: '', label: '----------工学----------', disabled: true },
  { value: '080101', label: '理论与应用力学' },
  { value: '080102', label: '工程力学' },

  { value: '080201', label: '机械工程' },
  { value: '080202', label: '机械设计制造及其自动化' },
  { value: '080203', label: '材料成型及控制工程' },
  { value: '080204', label: '机械电子工程' },
  { value: '080205', label: '工业设计' },
  { value: '080206', label: '过程装备与控制工程' },
  { value: '080207', label: '车辆工程' },
  { value: '080208', label: '汽车服务工程' },
  { value: '080209T', label: '机械工艺技术' },
  { value: '080210T', label: '微机电系统工程' },
  { value: '080211T', label: '机电技术教育' },
  { value: '080212T', label: '汽车维修工程教育' },
  { value: '080213T', label: '智能制造工程' },
  { value: '080214T', label: '智能车辆工程' },
  { value: '080215T', label: '仿生科学与工程' },
  { value: '080216T', label: '新能源汽车工程' },
  { value: '080217T', label: '增材制造工程' },
  { value: '080218T', label: '智能交互设计' },
  { value: '080219T', label: '应急装备技术与工程' },

  { value: '080301', label: '测控技术与仪器' },
  { value: '080302T', label: '精密仪器' },
  { value: '080303T', label: '智能感知工程' },

  { value: '080401', label: '材料科学与工程' },
  { value: '080402', label: '材料物理' },
  { value: '080403', label: '材料化学' },
  { value: '080404', label: '冶金工程' },
  { value: '080405', label: '金属材料工程' },
  { value: '080406', label: '无机非金属材料工程' },
  { value: '080407', label: '高分子材料与工程' },
  { value: '080408', label: '复合材料与工程' },
  { value: '080409T', label: '粉体材料科学与工程' },
  { value: '080410T', label: '宝石及材料工艺学' },
  { value: '080411T', label: '焊接技术与工程' },
  { value: '080412T', label: '功能材料' },
  { value: '080413T', label: '纳米材料与技术' },
  { value: '080414T', label: '新能源材料与器件' },
  { value: '080415T', label: '材料设计科学与工程' },
  { value: '080416T', label: '复合材料成型工程' },
  { value: '080417T', label: '智能材料与结构' },
  { value: '080418T', label: '光电信息材料与器件' },
  { value: '080419T', label: '生物材料' },

  { value: '080501', label: '能源与动力工程' },
  { value: '080502T', label: '能源与环境系统工程' },
  { value: '080503T', label: '新能源科学与工程' },
  { value: '080504T', label: '储能科学与工程' },
  { value: '080505T', label: '能源服务工程' },
  { value: '080506TK', label: '氢能科学与工程' },
  { value: '080507TK', label: '可持续能源' },

  { value: '080601', label: '电气工程及其自动化' },
  { value: '080602T', label: '智能电网信息工程' },
  { value: '080603T', label: '光源与照明' },
  { value: '080604T', label: '电气工程与智能控制' },
  { value: '080605T', label: '电机电器智能化' },
  { value: '080606T', label: '电缆工程' },
  { value: '080607T', label: '能源互联网工程' },
  { value: '080608TK', label: '智慧能源工程' },
  { value: '080609T', label: '电动载运工程' },

  { value: '080701', label: '电子信息工程' },
  { value: '080702', label: '电子科学与技术' },
  { value: '080703', label: '通信工程' },
  { value: '080704', label: '微电子科学与工程' },
  { value: '080705', label: '光电信息科学与工程' },
  { value: '080706', label: '信息工程' },
  { value: '080707T', label: '广播电视工程' },
  { value: '080708T', label: '水声工程' },
  { value: '080709T', label: '电子封装技术' },
  { value: '080710T', label: '集成电路设计与集成系统' },
  { value: '080711T', label: '医学信息工程' },
  { value: '080712T', label: '电磁场与无线技术' },
  { value: '080713T', label: '电波传播与天线' },
  { value: '080714T', label: '电子信息科学与技术' },
  { value: '080715T', label: '电信工程及管理' },
  { value: '080716T', label: '应用电子技术教育' },
  { value: '080717T', label: '人工智能' },
  { value: '080718T', label: '海洋信息工程' },
  { value: '080719T', label: '柔性电子学' },
  { value: '080720T', label: '智能测控工程' },

  { value: '080801', label: '自动化' },
  { value: '080802T', label: '轨道交通信号与控制' },
  { value: '080803T', label: '机器人工程' },
  { value: '080804T', label: '邮政工程' },
  { value: '080805T', label: '核电技术与控制工程' },
  { value: '080806T', label: '智能装备与系统' },
  { value: '080807T', label: '工业智能' },
  { value: '080808T', label: '智能工程与创意设计' },

  { value: '080901', label: '计算机科学与技术' },
  { value: '080902', label: '软件工程' },
  { value: '080903', label: '网络工程' },
  { value: '080904K', label: '信息安全' },
  { value: '080905', label: '物联网工程' },
  { value: '080906', label: '数字媒体技术' },
  { value: '080907T', label: '智能科学与技术' },
  { value: '080908T', label: '空间信息与数字技术' },
  { value: '080909T', label: '电子与计算机工程' },
  { value: '080910T', label: '数据科学与大数据技术' },
  { value: '080911TK', label: '网络空间安全' },
  { value: '080912T', label: '新媒体技术' },
  { value: '080913T', label: '电影制作' },
  { value: '080914TK', label: '保密技术' },
  { value: '080915T', label: '服务科学与工程' },
  { value: '080916T', label: '虚拟现实技术' },
  { value: '080917T', label: '区块链工程' },
  { value: '080918TK', label: '密码科学与技术' },

  { value: '081001', label: '土木工程' },
  { value: '081002', label: '建筑环境与能源应用工程' },
  { value: '081003', label: '给排水科学与工程' },
  { value: '081004', label: '建筑电气与智能化' },
  { value: '081005T', label: '城市地下空间工程' },
  { value: '081006T', label: '道路桥梁与渡河工程' },
  { value: '081007T', label: '铁道工程' },
  { value: '081008T', label: '智能建造' },
  { value: '081009T', label: '土木、水利与海洋工程' },
  { value: '081010T', label: '土木、水利与交通工程' },
  { value: '081011T', label: '城市水系统工程' },
  { value: '081012T', label: '智能建造与智慧交通' },

  { value: '081101', label: '水利水电工程' },
  { value: '081102', label: '水文与水资源工程' },
  { value: '081103', label: '港口航道与海岸工程' },
  { value: '081104T', label: '水务工程' },
  { value: '081105T', label: '水利科学与工程' },
  { value: '081106T', label: '智慧水利' },

  { value: '081201', label: '测绘工程' },
  { value: '081202', label: '遥感科学与技术' },
  { value: '081203T', label: '导航工程' },
  { value: '081204T', label: '地理国情监测' },
  { value: '081205T', label: '地理空间信息工程' },

  { value: '081301', label: '化学工程与工艺' },
  { value: '081302', label: '制药工程' },
  { value: '081303T', label: '资源循环科学与工程' },
  { value: '081304T', label: '能源化学工程' },
  { value: '081305T', label: '化学工程与工业生物工程' },
  { value: '081306T', label: '化工安全工程' },
  { value: '081307T', label: '涂料工程' },
  { value: '081308T', label: '精细化工' },

  { value: '081401', label: '地质工程' },
  { value: '081402', label: '勘查技术与工程' },
  { value: '081403', label: '资源勘查工程' },

  { value: '081404T', label: '地下水科学与工程' },
  { value: '081405T', label: '旅游地学与规划工程' },
  { value: '081406T', label: '智能地球探测' },
  { value: '081407T', label: '资源环境大数据工程' },

  { value: '081501', label: '采矿工程' },
  { value: '081502', label: '石油工程' },
  { value: '081503', label: '矿物加工工程' },
  { value: '081504', label: '油气储运工程' },
  { value: '081505T', label: '矿物资源工程' },
  { value: '081506T', label: '海洋油气工程' },
  { value: '081507T', label: '智能采矿工程' },
  { value: '081508TK', label: '碳储科学与工程' },

  { value: '081601', label: '纺织工程' },
  { value: '081602', label: '服装设计与工程' },
  { value: '081603T', label: '非织造材料与工程' },
  { value: '081604T', label: '服装设计与工艺教育' },
  { value: '081605T', label: '丝绸设计与工程' },

  { value: '081701', label: '轻化工程' },
  { value: '081702', label: '包装工程' },
  { value: '081703', label: '印刷工程' },
  { value: '081704T', label: '香料香精技术与工程' },
  { value: '081705T', label: '化妆品技术与工程' },
  { value: '081706TK', label: '生物质能源与材料' },

  { value: '081801', label: '交通运输' },
  { value: '081802', label: '交通工程' },
  { value: '081803K', label: '航海技术' },
  { value: '081804K', label: '轮机工程' },
  { value: '081805K', label: '飞行技术' },
  { value: '081806T', label: '交通设备与控制工程' },
  { value: '081807T', label: '救助与打捞工程' },
  { value: '081808TK', label: '船舶电子电气工程' },
  { value: '081809T', label: '轨道交通电气与控制' },
  { value: '081810T', label: '邮轮工程与管理' },
  { value: '081811T', label: '智慧交通' },
  { value: '081812T', label: '智能运输工程' },

  { value: '081901', label: '船舶与海洋工程' },
  { value: '081902T', label: '海洋工程与技术' },
  { value: '081903T', label: '海洋资源开发技术' },
  { value: '081904T', label: '海洋机器人' },
  { value: '081905T', label: '智慧海洋技术' },

  { value: '082001', label: '航空航天工程' },
  { value: '082002', label: '飞行器设计与工程' },
  { value: '082003', label: '飞行器制造工程' },
  { value: '082004', label: '飞行器动力工程' },
  { value: '082005', label: '飞行器环境与生命保障工程' },
  { value: '082006T', label: '飞行器质量与可靠性' },
  { value: '082007T', label: '飞行器适航技术' },
  { value: '082008T', label: '飞行器控制与信息工程' },
  { value: '082009T', label: '无人驾驶航空器系统工程' },
  { value: '082010T', label: '智能飞行器技术' },
  { value: '082011T', label: '空天智能电推进技术' },
  { value: '082012T', label: '飞行器运维工程' },

  { value: '082101', label: '武器系统与工程' },
  { value: '082102', label: '武器发射工程' },
  { value: '082103', label: '探测制导与控制技术' },
  { value: '082104', label: '弹药工程与爆炸技术' },
  { value: '082105', label: '特种能源技术与工程' },
  { value: '082106', label: '装甲车辆工程' },
  { value: '082107', label: '信息对抗技术' },
  { value: '082108T', label: '智能无人系统技术' },

  { value: '082201', label: '核工程与核技术' },
  { value: '082202', label: '辐射防护与核安全' },
  { value: '082203', label: '工程物理' },
  { value: '082204', label: '核化工与核燃料工程' },

  { value: '082301', label: '农业工程' },
  { value: '082302', label: '农业机械化及其自动化' },
  { value: '082303', label: '农业电气化' },
  { value: '082304', label: '农业建筑环境与能源工程' },
  { value: '082305', label: '农业水利工程' },
  { value: '082306T', label: '土地整治工程' },
  { value: '082307T', label: '农业智能装备工程' },

  { value: '082401', label: '森林工程' },
  { value: '082402', label: '木材科学与工程' },
  { value: '082403', label: '林产化工' },
  { value: '082404T', label: '家具设计与工程' },
  { value: '082405T', label: '木结构建筑与材料' },

  { value: '082501', label: '环境科学与工程' },
  { value: '082502', label: '环境工程' },
  { value: '082503', label: '环境科学' },
  { value: '082504', label: '环境生态工程' },
  { value: '082505T', label: '环保设备工程' },
  { value: '082506T', label: '资源环境科学' },
  { value: '082507T', label: '水质科学与技术' },

  { value: '082601', label: '生物医学工程' },
  { value: '082602T', label: '假肢矫形工程' },
  { value: '082603T', label: '临床工程技术' },
  { value: '082604T', label: '康复工程' },

  { value: '082701', label: '食品科学与工程' },
  { value: '082702', label: '食品质量与安全' },
  { value: '082703', label: '粮食工程' },
  { value: '082704', label: '乳品工程' },
  { value: '082705', label: '酿酒工程' },
  { value: '082706T', label: '葡萄与葡萄酒工程' },
  { value: '082707T', label: '食品营养与检验教育' },
  { value: '082708T', label: '烹饪与营养教育' },
  { value: '082709T', label: '食品安全与检测' },
  { value: '082710T', label: '食品营养与健康' },
  { value: '082711T', label: '食用菌科学与工程' },
  { value: '082712T', label: '白酒酿造工程' },

  { value: '082801', label: '建筑学' },
  { value: '082802', label: '城乡规划' },
  { value: '082803', label: '风景园林' },
  { value: '082804T', label: '历史建筑保护工程' },
  { value: '082805T', label: '人居环境科学与技术' },
  { value: '082806T', label: '城市设计' },
  { value: '082807T', label: '智慧建筑与建造' },

  { value: '082901', label: '安全工程' },
  { value: '082902T', label: '应急技术与管理' },
  { value: '082903T', label: '职业卫生工程' },
  { value: '082904T', label: '安全生产监管' },

  { value: '083001', label: '生物工程' },
  { value: '083002T', label: '生物制药' },
  { value: '083003T', label: '合成生物学' },

  { value: '083101K', label: '刑事科学技术' },
  { value: '083102K', label: '消防工程' },
  { value: '083103TK', label: '交通管理工程' },
  { value: '083104TK', label: '安全防范工程' },
  { value: '083105TK', label: '公安视听技术' },
  { value: '083106TK', label: '抢险救援指挥与技术' },
  { value: '083107TK', label: '火灾勘查' },
  { value: '083108TK', label: '网络安全与执法' },
  { value: '083109TK', label: '核生化消防' },
  { value: '083110TK', label: '海警舰艇指挥与技术' },
  { value: '083111TK', label: '数据警务技术' },
  { value: '083112TK', label: '食品药品环境犯罪侦查技术' },

  { value: '083201TK', label: '未来机器人' },
  { value: '', label: '----------农学----------', disabled: true },
  { value: '090101', label: '农学' },
  { value: '090102', label: '园艺' },
  { value: '090103', label: '植物保护' },
  { value: '090104', label: '植物科学与技术' },
  { value: '090105', label: '种子科学与工程' },
  { value: '090106', label: '设施农业科学与工程' },
  { value: '090107T', label: '茶学' },
  { value: '090108T', label: '烟草' },
  { value: '090109T', label: '应用生物科学' },
  { value: '090110T', label: '农艺教育' },
  { value: '090111T', label: '园艺教育' },
  { value: '090112T', label: '智慧农业' },
  { value: '090113T', label: '菌物科学与工程' },
  { value: '090114T', label: '农药化肥' },
  { value: '090115T', label: '生物农药科学与工程' },
  { value: '090116TK', label: '生物育种科学' },

  { value: '090201', label: '农业资源与环境' },
  { value: '090202', label: '野生动物与自然保护区管理' },
  { value: '090203', label: '水土保持与荒漠化防治' },
  { value: '090204T', label: '生物质科学与工程' },
  { value: '090205T', label: '土地科学与技术' },
  { value: '090206T', label: '湿地保护与恢复' },
  { value: '090207TK', label: '国家公园建设与管理' },

  { value: '090301', label: '动物科学' },
  { value: '090302T', label: '蚕学' },
  { value: '090303T', label: '蜂学' },
  { value: '090304T', label: '经济动物学' },
  { value: '090305T', label: '马业科学' },
  { value: '090306T', label: '饲料工程' },
  { value: '090307T', label: '智慧牧业科学与工程' },

  { value: '090401', label: '动物医学' },
  { value: '090402', label: '动物药学' },
  { value: '090403T', label: '动植物检疫' },
  { value: '090404T', label: '实验动物学' },
  { value: '090405T', label: '中兽医学' },
  { value: '090406TK', label: '兽医公共卫生' },

  { value: '090501', label: '林学' },
  { value: '090502', label: '园林' },
  { value: '090503', label: '森林保护' },
  { value: '090504T', label: '经济林' },
  { value: '090505T', label: '智慧林业' },

  { value: '090601', label: '水产养殖学' },
  { value: '090602', label: '海洋渔业科学与技术' },
  { value: '090603T', label: '水族科学与技术' },
  { value: '090604TK', label: '水生动物医学' },

  { value: '090701', label: '草业科学' },
  { value: '090702T', label: '草坪科学与工程' },
  { value: '', label: '----------医学----------', disabled: true },
  { value: '100101K', label: '基础医学' },
  { value: '100102TK', label: '生物医学' },
  { value: '100103T', label: '生物医学科学' },

  { value: '100201K', label: '临床医学' },
  { value: '100202TK', label: '麻醉学' },
  { value: '100203TK', label: '医学影像学' },
  { value: '100204TK', label: '眼视光医学' },
  { value: '100205TK', label: '精神医学' },
  { value: '100206TK', label: '放射医学' },
  { value: '100207TK', label: '儿科学' },

  { value: '100301K', label: '口腔医学' },

  { value: '100401K', label: '预防医学' },
  { value: '100402', label: '食品卫生与营养学' },
  { value: '100403TK', label: '妇幼保健医学' },
  { value: '100404TK', label: '卫生监督' },
  { value: '100105TK', label: '全球健康学' },
  { value: '100406T', label: '运动与公共健康' },

  { value: '100501K', label: '中医学' },
  { value: '100502K', label: '针灸推拿学' },
  { value: '100503K', label: '藏医学' },
  { value: '100504K', label: '蒙医学' },
  { value: '100505K', label: '维医学' },
  { value: '100506K', label: '壮医学' },
  { value: '100507K', label: '哈医学' },
  { value: '100508TK', label: '傣医学' },
  { value: '100509TK', label: '回医学' },
  { value: '100510TK', label: '中医康复学' },
  { value: '100511TK', label: '中医养生学' },
  { value: '100512TK', label: '中医儿科学' },
  { value: '100513TK', label: '中医骨伤科学' },

  { value: '100601K', label: '中西医临床医学' },

  { value: '100701', label: '药学' },
  { value: '100702', label: '药物制剂' },
  { value: '100703TK', label: '临床药学' },
  { value: '100704T', label: '药事管理' },
  { value: '100705T', label: '药物分析' },
  { value: '100706T', label: '药物化学' },
  { value: '100707T', label: '海洋药学' },
  { value: '100708T', label: '化妆品科学与技术' },

  { value: '100801', label: '中药学' },
  { value: '100802', label: '中药资源与开发' },
  { value: '100803T', label: '藏药学' },
  { value: '100804T', label: '蒙药学' },
  { value: '100805T', label: '中药制药' },
  { value: '100806T', label: '中草药栽培与鉴定' },

  { value: '100901K', label: '法医学' },

  { value: '101001', label: '医学检验技术' },
  { value: '101002', label: '医学实验技术' },
  { value: '101003', label: '医学影像技术' },
  { value: '101004', label: '眼视光学' },
  { value: '101005', label: '康复治疗学' },
  { value: '101006', label: '口腔医学技术' },
  { value: '101007', label: '卫生检验与检疫' },
  { value: '101008T', label: '听力与言语康复学' },
  { value: '101009T', label: '康复物理治疗' },
  { value: '101010T', label: '康复作业治疗' },
  { value: '101011T', label: '智能医学工程' },
  { value: '101012T', label: '生物医药数据科学' },
  { value: '101013T', label: '智能影像工程' },
  { value: '101014TK', label: '医工学' },

  { value: '101101', label: '护理学' },
  { value: '101102T', label: '助产学' },
  { value: '', label: '----------管理学----------', disabled: true },
  { value: '120101', label: '管理科学' },
  { value: '120102', label: '信息管理与信息系统' },
  { value: '120103', label: '工程管理' },
  { value: '120104', label: '房地产开发与管理' },
  { value: '120105', label: '工程造价' },
  { value: '120106TK', label: '保密管理' },
  { value: '120107T', label: '邮政管理' },
  { value: '120108T', label: '大数据管理与应用' },
  { value: '120109T', label: '工程审计' },
  { value: '120110T', label: '计算金融' },
  { value: '120111T', label: '应急管理' },

  { value: '120201K', label: '工商管理' },
  { value: '120202', label: '市场营销' },
  { value: '120203K', label: '会计学' },
  { value: '120204', label: '财务管理' },
  { value: '120205', label: '国际商务' },
  { value: '120206', label: '人力资源管理' },
  { value: '120207', label: '审计学' },
  { value: '120208', label: '资产评估' },
  { value: '120209', label: '物业管理' },
  { value: '120210', label: '文化产业管理' },
  { value: '120211T', label: '劳动关系' },
  { value: '120212T', label: '体育经济与管理' },
  { value: '120213T', label: '财务会计教育' },
  { value: '120214T', label: '市场营销教育' },
  { value: '120215T', label: '零售业管理' },
  { value: '120216T', label: '创业管理' },
  { value: '120217TK', label: '海关稽查' },

  { value: '120301', label: '农林经济管理' },
  { value: '120302', label: '农村区域发展' },
  { value: '120303TK', label: '乡村治理' },

  { value: '120401', label: '公共事业管理' },
  { value: '120402', label: '行政管理' },
  { value: '120403', label: '劳动与社会保障' },
  { value: '120404', label: '土地资源管理' },
  { value: '120405', label: '城市管理' },
  { value: '120406TK', label: '海关管理' },
  { value: '120407T', label: '交通管理' },
  { value: '120408T', label: '海事管理' },
  { value: '120409T', label: '公共关系学' },
  { value: '120410T', label: '健康服务与管理' },
  { value: '120411TK', label: '海警后勤管理' },
  { value: '120412T', label: '医疗产品管理' },
  { value: '120413T', label: '医疗保险' },
  { value: '120414T', label: '养老服务管理' },
  { value: '120415TK', label: '海关检验检疫安全' },
  { value: '120416TK', label: '海外安全管理' },
  { value: '120417T', label: '自然资源登记与管理' },
  { value: '120418T', label: '慈善管理' },
  { value: '120419TK', label: '航空安防管理' },
  { value: '120420TK', label: '无障碍管理' },

  { value: '120501', label: '图书馆学' },
  { value: '120502', label: '档案学' },
  { value: '120503', label: '信息资源管理' },

  { value: '120601', label: '物流管理' },
  { value: '120602', label: '物流工程' },
  { value: '120603T', label: '采购管理' },
  { value: '120604T', label: '供应链管理' },

  { value: '120701', label: '工业工程' },
  { value: '120702T', label: '标准化工程' },
  { value: '120703T', label: '质量管理工程' },

  { value: '120801', label: '电子商务' },
  { value: '120802T', label: '电子商务及法律' },
  { value: '120803T', label: '跨境电子商务' },

  { value: '120901K', label: '旅游管理' },
  { value: '120902', label: '酒店管理' },
  { value: '120903', label: '会展经济与管理' },
  { value: '120904T', label: '旅游管理与服务教育' },
  { value: '', label: '----------艺术学----------', disabled: true },
  { value: '130101', label: '艺术史论' },
  { value: '130102T', label: '艺术管理' },
  { value: '130103T', label: '非物质文化遗产保护' },

  { value: '130201', label: '音乐表演' },
  { value: '130202', label: '音乐学' },
  { value: '130203', label: '作曲与作曲技术理论' },
  { value: '130204', label: '舞蹈表演' },
  { value: '130205', label: '舞蹈学' },
  { value: '130206', label: '舞蹈编导' },
  { value: '130207T', label: '舞蹈教育' },
  { value: '130208TK', label: '航空服务艺术与管理' },
  { value: '130209T', label: '流行音乐' },
  { value: '130210T', label: '音乐治疗' },
  { value: '130211T', label: '流行舞蹈' },
  { value: '130212T', label: '音乐教育' },

  { value: '010301', label: '表演' },
  { value: '130302', label: '戏剧学' },
  { value: '130303', label: '电影学' },
  { value: '130304', label: '戏剧影视文学' },
  { value: '130305', label: '广播电视编导' },
  { value: '130306', label: '戏剧影视导演' },
  { value: '130307', label: '戏剧影视美术设计' },
  { value: '130308', label: '录音艺术' },
  { value: '130309', label: '播音与主持艺术' },
  { value: '130310', label: '动画' },
  { value: '130311T', label: '影视摄影与制作' },
  { value: '130312T', label: '影视技术' },
  { value: '130313T', label: '戏剧教育' },
  { value: '130314TK', label: '曲艺' },
  { value: '130315TK', label: '音乐剧' },

  { value: '130401', label: '美术学' },
  { value: '130402', label: '绘画' },
  { value: '130403', label: '雕塑' },
  { value: '130404', label: '摄影' },
  { value: '130405T', label: '书法学' },
  { value: '130406T', label: '中国画' },
  { value: '130407TK', label: '实验艺术' },
  { value: '130408TK', label: '跨媒体艺术' },
  { value: '130409T', label: '文物保护与修复' },
  { value: '130410T', label: '漫画' },
  { value: '130411T', label: '纤维艺术' },
  { value: '130412TK', label: '科技艺术' },
  { value: '130413TK', label: '美术教育' },

  { value: '130501', label: '艺术设计学' },
  { value: '130502', label: '视觉传达设计' },
  { value: '130503', label: '环境设计' },
  { value: '130504', label: '产品设计' },
  { value: '130505', label: '服装与服饰设计' },
  { value: '130506', label: '公共艺术' },
  { value: '130507', label: '工艺美术' },
  { value: '130508', label: '数字媒体艺术' },
  { value: '130509T', label: '艺术与科技' },
  { value: '130510TK', label: '陶瓷艺术设计' },
  { value: '130511T', label: '新媒体艺术' },
  { value: '130512T', label: '包装设计' },
  { value: '130513TK', label: '珠宝首饰设计与工艺' },
];
export default specializedBackground;
